import React from "react"
import { Box, Typography, Button } from "@mui/material"
import { useSelector, useDispatch } from "react-redux"
import { setChatWindowShow, setButton } from "../../../../redux/slices/navSlice"
import ChatBubbleOutlineRoundedIcon from "@mui/icons-material/ChatBubbleOutlineRounded"
import "./chatbot.css"
import MoxoChatContainer from "./useChatHook"
import navButton from "../../../Layout/navButtons"
const userChecks = JSON.parse(window.localStorage.getItem("user"))
const isRmChecks = userChecks?.userTypeId?.description === "Entity RM" ? true : false

const assistanceButtons = [
    {
        index: 26,
        text: "FAQs",
        path: "/enquiry"
    },
    {
        index: 27,
        text: isRmChecks ? "Chat" : "Chat with RM",
        path: "/chatqueries"
    },
    {
        index: 28,
        text: "Ask Gaia",
        path: "/chatgpt"
    }
]
const ChatBotIcon = () => {
    const dispatch = useDispatch()
    const unreadCount = useSelector((state) => state.nav.unreadCount)
    const show = useSelector((state) => state.nav.chatWindowShow)
    // eslint-disable-next-line no-unused-vars
    const text = useSelector((state) => state.nav.text)

    return (
        <>
            <Box
                className={`chatbotPop `}
                style={{ visibility: show ? "visible" : "hidden" }}
            >
                <Box
                    className={`closeIcon `}
                    onClick={() => {
                        dispatch(setChatWindowShow(false))
                        const foundButton = navButton?.find(
                            (button) => button.text === text
                        )
                        if (foundButton) {
                            dispatch(setButton(foundButton))
                        } else {
                            let assist = assistanceButtons?.find(
                                (button) => button.text === text
                            )
                            dispatch(setButton(assist))
                        }
                    }}
                >
                    <Button
                        variant="contained"
                        sx={{ backgroundColor: "#6a3be0" }}
                    >
                        Close
                    </Button>
                </Box>
                <MoxoChatContainer />
            </Box>
            {!show && (
                <Box
                    onClick={() => {
                        dispatch(setChatWindowShow(true))
                    }}
                    style={{
                        position: "absolute",
                        right: 30,
                        bottom: 20,
                        background: "#6A3BE0",
                        borderRadius: 50,
                        width: 50,
                        height: 50,
                        zIndex: 0,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                >
                    {unreadCount !== "" && (
                        <Typography className="countText">
                            {unreadCount}
                        </Typography>
                    )}
                    <ChatBubbleOutlineRoundedIcon style={{ color: "#ffffff", fontSize: 23 }} />
                </Box>
            )}
        </>
    )
}

export default ChatBotIcon
