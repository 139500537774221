import { createSlice } from "@reduxjs/toolkit"

const defaultData = {
    attachments: []
}

const relatedPartySlice = createSlice({
    name: "selfOnboard",
    initialState: defaultData,
    reducers: {
        saveAttachment: (state, action) => {
            const prevList = state.attachments
            state.attachments = [action.payload, ...prevList]
        },
        deleteAttachment: (state, action) => {
            const newRecord = state.attachments.filter(
                (attachment) => attachment.id !== action.payload
            )
            state.attachments = newRecord
        },
        clearAttachments: (state) => {
            state.attachments = []
        }
    }
})

export const { saveAttachment, deleteAttachment, clearAttachments } =
    relatedPartySlice.actions

export default relatedPartySlice.reducer
