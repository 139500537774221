import * as React from "react"
import Box from "@mui/material/Box"
import { Pagination } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid" // eslint-disable-next-line
import { useSelector, useDispatch } from "react-redux"

export default function ModalTableGridTable(props) {
    // eslint-disable-next-line
    const checkStatus = useSelector((state) => state.modal) // eslint-disable-next-line
    const dispatch = useDispatch()
    const [currentPage, setCurrentPage] = React.useState(1)
    const handleChangePaginations = (event, value) => {
        setCurrentPage(value)
    }
    function localPaginationCount(page_size, page_number, data) {
        return data.slice(
            (page_number - 1) * page_size,
            page_number * page_size
        )
    }

    return (
        <Box sx={{ width: "100%", height: "350px" }}>
            {checkStatus?.loading && <>Loading...</>}

            {!checkStatus?.loading && (
                <DataGrid
                    sx={{
                        border: "none",
                        fontFamily: "Mona Sans",
                        height: "300px",
                        "& .MuiDataGrid-row:nth-of-type(even)": {
                            backgroundColor: "#F0EDED"
                        },
                        "& .MuiDataGrid-iconSeparator": {
                            display: "none !important"
                        },

                        "&>.MuiDataGrid-main": {
                            "&>.MuiDataGrid-columnHeaders": {
                                border: "none"
                            },

                            "& div div div div >.MuiDataGrid-cell": {
                                border: "none"
                            }
                        }
                    }}
                    disableColumnMenu
                    headerHeight={55}
                    hideFooter={true}
                    rowHeight={25}
                    rows={localPaginationCount(
                        10,
                        currentPage,
                        checkStatus?.data
                    )}
                    getRowId={(row) => row.hniId}
                    columns={checkStatus.column}
                />
            )}

            {!checkStatus?.loading && (
                <Pagination
                    sx={{
                        "& .MuiPaginationItem-root.Mui-selected ": {
                            backgroundColor: "black !important",
                            color: "white !important"
                        }
                    }}
                    count={Math.ceil(checkStatus?.data?.length / 10)}
                    page={currentPage}
                    onChange={handleChangePaginations}
                    style={{ float: "right", marginTop: "20px" }}
                />
            )}
        </Box>
    )
}
