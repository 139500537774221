import { useCallback, useState } from "react"

export const useSelfOnboardApi = () => {
    const [isLoading, setIsLoading] = useState()
    const [error, setError] = useState()
    const [data, setData] = useState()

    const get = useCallback(async ({ apiCaller, params = {} }) => {
        setIsLoading(true)
        setError("")

        try {
            const resp = await apiCaller(params)
            setData(resp.data)
            return resp.data
        } catch (err) {
            setError(err?.response?.data?.detail)
            return err?.response || err
        } finally {
            setIsLoading(false)
        }
    }, [])

    const getById = useCallback(async ({ apiCaller, id }) => {
        setIsLoading(true)
        setError("")

        try {
            const resp = await apiCaller(id)
            setData(resp.data)
            return resp.data
        } catch (err) {
            setError(err?.response?.data?.detail)
            return err?.response
        } finally {
            setIsLoading(false)
        }
    }, [])

    const post = useCallback(async ({ apiCaller, payload }) => {
        setIsLoading(true)
        setError("")
        try {
            const resp = await apiCaller(payload)
            return resp
        } catch (err) {
            setError(err?.response?.data?.detail)
            return err?.response || err
        } finally {
            setIsLoading(false)
        }
    }, [])

    const update = useCallback(async ({ apiCaller, payload, id }) => {
        setIsLoading(true)
        setError("")
        try {
            const resp = await apiCaller({ payload, id })
            return resp
        } catch (err) {
            setError(err?.response?.data?.detail)
            return err?.response || err
        } finally {
            setIsLoading(false)
        }
    }, [])

    const doDelete = useCallback(async ({ apiCaller, id }) => {
        setIsLoading(true)
        setError("")
        try {
            const resp = await apiCaller(id)
            return resp
        } catch (err) {
            setError(err?.response?.data?.detail)
            return err?.response || err
        } finally {
            setIsLoading(false)
        }
    }, [])
    return { post, isLoading, error, get, data, getById, update, doDelete }
}
