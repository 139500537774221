// ** 3rd party lib
import { AddOutlined } from "@mui/icons-material"
import { toast } from "react-toastify"
import { useState } from "react"
import { Tooltip } from "@mui/material"
import * as Yup from "yup"
import { useDispatch, useSelector } from "react-redux"

// ** Components
import { ConfirmDeleteDialog } from "components/common/Ui/Dialog"
import FooterAction from "../KycFooterAction"
import KycSectionWrapper from "../KycSectionWrapper"
import KycDocumentList from "../KycDocumentList"
import KycDocumentModal from "../KycDocumentModal"
import FormWrapper from "../../components/FormWrapper"
import { Button } from "components/common/Ui/Form"

// ** Helper func
import { checkPdfFormat, checkPdfSize } from "../../utils"
import {
    goToNextEntityKyc,
    saveEntityProofOfIdentity,
    saveEntityProofOfAddress,
    setUboIdentityProof,
    setUboAddressProof
} from "redux/slices/selfOnboardSlice"
import { useSelfOnboardApi } from "hooks/useSelfOnboardApi"
import {
    deleteSelfOnboardKyc,
    saveSelfOnboardKyc
} from "services/prospectsServices"

// ** Constants
const SUCCESS_MSG = "Success! Your document is now safe and saved"
const FAILED_MSG = "Something went wrong! We couldn't save your document."
const SUCCESS_DEL_MSG = "Success! Your document is deleted"
const FAILED_DEL_MSG = "Something went wrong! We couldn't delete your document."
const title = "KYC documents"
const subtitle =
    "To comply with regulatory requirements, we need you to upload Know Your Customer (KYC) documents. These documents will help verify your identity."

const fileSchema = Yup.mixed()
    .required("Document required")
    .test(
        "fileformat",
        "Supported document formats: pdf,jpg,jpeg,png",
        checkPdfFormat
    )
    .test("filesize", "Document size must not exceed 9 MB", checkPdfSize)

export default function EntityKyc() {
    useSelector((state) => console.log(state.selfOnboard))
    const {
        prospect_id,
        entityKycDetails: {
            proofOfUboIdentity,
            proofOfUboAddress,
            proofOfAddress,
            proofOfIdentity
        }
    } = useSelector((state) => state.selfOnboard)

    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [modalData, setModalData] = useState({ name: "", title: "" })
    const [deletePayload, setDeletePayload] = useState(null)
    const [showDocumentModal, setShowDocumentModal] = useState(false)
    const { isLoading, post } = useSelfOnboardApi()
    const { isLoading: isDeleting, post: docDelete } = useSelfOnboardApi()
    const dispatch = useDispatch()

    const proofOfAddressSaved = proofOfAddress !== null
    const proofOfIdentitySaved = proofOfIdentity !== null

    const deleteDocument = async () => {
        const payload = {
            ...deletePayload,
            prospectId: prospect_id
        }

        const resp = await docDelete({
            apiCaller: deleteSelfOnboardKyc,
            payload
        })

        if (resp?.data?.status) {
            switch (payload.field) {
                case "proofOfIdentity":
                    dispatch(saveEntityProofOfIdentity(null))
                    break
                case "proofOfAddress":
                    dispatch(saveEntityProofOfAddress(null))
                    break
                case "otherDocuments":
                    const otherAddrList = proofOfUboAddress.filter(
                        (doc) => doc.id !== payload.id
                    )
                    dispatch(setUboAddressProof(otherAddrList))
                    const otherIdenList = proofOfUboIdentity.filter(
                        (doc) => doc.id !== payload.id
                    )
                    dispatch(setUboIdentityProof(otherIdenList))
                    break
                default:
            }

            toast.success(SUCCESS_DEL_MSG)
            setShowDeleteModal(false)
        } else {
            toast.error(resp?.data?.errorMessage || FAILED_DEL_MSG)
        }
    }

    const handleDeleteClick = (payload) => {
        setDeletePayload(payload)
        setShowDeleteModal(true)
    }

    const handleNext = () => {
        dispatch(goToNextEntityKyc())
    }

    const handleSubmit = async (data) => {
        const field = Object.keys(data).at(0)
        const payload = new FormData()

        payload.append(field, data[field])

        payload.append(
            "prospectKycRequest",
            JSON.stringify(
                {
                    prospectId: prospect_id,
                    field,
                    documentType: modalData.documentType
                },
                null,
                2
            )
        )

        const resp = await post({ apiCaller: saveSelfOnboardKyc, payload })

        if (resp.data?.status) {
            toast.success(SUCCESS_MSG)
            const { proofOfAddress, proofOfIdentity, otherDocuments } =
                resp.data.body

            // ** Dispatch and close the modal

            switch (field) {
                case "proofOfIdentity":
                    dispatch(
                        saveEntityProofOfIdentity({ ...proofOfIdentity, field })
                    )
                    break

                case "proofOfAddress":
                    dispatch(
                        saveEntityProofOfAddress({ field, ...proofOfAddress })
                    )
                    break
                case "otherDocuments":
                    if (modalData.documentType === "otherIdentityDocument") {
                        const otherIdenList = otherDocuments.filter(
                            (doc) =>
                                doc.documentType === "otherIdentityDocument" &&
                                !!doc.url
                        )
                        dispatch(
                            setUboIdentityProof(
                                otherIdenList.map((doc) => ({
                                    ...doc,
                                    field
                                }))
                            )
                        )
                    } else {
                        const otherAddrList = otherDocuments.filter(
                            (doc) =>
                                doc.documentType === "otherAddressDocument" &&
                                !!doc.url
                        )
                        dispatch(
                            setUboAddressProof(
                                otherAddrList.map((doc) => ({
                                    ...doc,
                                    field
                                }))
                            )
                        )
                    }

                    break
                default:
            }
            setShowDocumentModal(false)
        } else {
            toast.error(resp.data.errorMessage || FAILED_MSG)
        }
    }

    const handleAddClick = (field) => {
        switch (field) {
            case "proofOfIdentity":
                setModalData({
                    name: field,
                    title: "Certificate of Incorporation or Trust Deed"
                })
                break

            case "proofOfAddress":
                setModalData({ name: field, title: "Proof of Address" })
                break
            case "uboIdentity":
                setModalData({
                    name: "otherDocuments",
                    title: "Proof of Identity of All UBO",
                    documentType: "otherIdentityDocument"
                })
                break
            case "uboAddress":
                setModalData({
                    name: "otherDocuments",
                    title: "Proof of Address of All UBO",
                    documentType: "otherAddressDocument"
                })
                break
            default:
        }
        setShowDocumentModal(true)
    }

    return (
        <>
            <FormWrapper title={title} subtitle={subtitle}>
                <KycSectionWrapper
                    title="Certificate of Incorporation or Trust Deed"
                    headerSlot={
                        <Tooltip
                            title={
                                proofOfIdentitySaved
                                    ? "Proof of identity document already uploaded. To upload new one, you must delete existing one"
                                    : null
                            }
                        >
                            <span>
                                <Button
                                    disabled={proofOfIdentitySaved}
                                    onClick={() =>
                                        handleAddClick("proofOfIdentity")
                                    }
                                    variant="outlined"
                                    startIcon={AddOutlined}
                                >
                                    Document
                                </Button>
                            </span>
                        </Tooltip>
                    }
                >
                    <KycDocumentList
                        data={proofOfIdentitySaved ? [proofOfIdentity] : []}
                        handleDeleteClick={() =>
                            handleDeleteClick({
                                field: "proofOfIdentity",
                                url: proofOfIdentity.url
                            })
                        }
                    />
                </KycSectionWrapper>
                <KycSectionWrapper
                    title="Proof Of Address"
                    headerSlot={
                        <Tooltip
                            title={
                                proofOfAddressSaved
                                    ? "Proof of address document already uploaded. To upload new one, you must delete existing one"
                                    : null
                            }
                        >
                            <span>
                                <Button
                                    disabled={proofOfAddressSaved}
                                    onClick={() =>
                                        handleAddClick("proofOfAddress")
                                    }
                                    variant="outlined"
                                    startIcon={AddOutlined}
                                >
                                    Document
                                </Button>
                            </span>
                        </Tooltip>
                    }
                >
                    <KycDocumentList
                        data={proofOfAddressSaved ? [proofOfAddress] : []}
                        handleDeleteClick={() =>
                            handleDeleteClick({
                                field: "proofOfAddress",
                                url: proofOfAddress.url
                            })
                        }
                    />
                </KycSectionWrapper>
                <KycSectionWrapper
                    title="Proof Of Identity of all UBO"
                    headerSlot={
                        <Button
                            onClick={() => handleAddClick("uboIdentity")}
                            variant="outlined"
                            startIcon={AddOutlined}
                        >
                            Document
                        </Button>
                    }
                >
                    <KycDocumentList
                        data={proofOfUboIdentity}
                        handleDeleteClick={handleDeleteClick}
                    />
                </KycSectionWrapper>
                <KycSectionWrapper
                    title="Proof Of Address of all UBO"
                    headerSlot={
                        <Button
                            variant="outlined"
                            startIcon={AddOutlined}
                            onClick={() => handleAddClick("uboAddress")}
                        >
                            Document
                        </Button>
                    }
                >
                    <KycDocumentList
                        data={proofOfUboAddress}
                        handleDeleteClick={handleDeleteClick}
                    />
                </KycSectionWrapper>
            </FormWrapper>
            <KycDocumentModal
                isLoading={isLoading}
                schema={Yup.object({ [modalData.name]: fileSchema })}
                initialValues={{ [modalData.name]: null }}
                name={modalData.name}
                showModal={showDocumentModal}
                closeModal={() => setShowDocumentModal(false)}
                title={modalData.title}
                handleSubmit={handleSubmit}
            />
            <FooterAction
                handleNext={handleNext}
                docSaved={proofOfIdentitySaved && proofOfAddressSaved}
            />
            <ConfirmDeleteDialog
                isLoading={isDeleting}
                handleCancelBtn={() => setShowDeleteModal(false)}
                showModal={showDeleteModal}
                handleDeleteBtn={deleteDocument}
            />
        </>
    )
}
