import React, { useState, useRef } from "react"
import {
    CircularProgress,
    Typography,
    List,
    Grid,
    Divider
} from "@mui/material"
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import RemoveIcon from "@mui/icons-material/Remove"

import moment from "moment"
import { useSelector } from "react-redux"

const MarketNews = ({ marketDetails }) => {
    const loading = useSelector((state) => state.general.isMarketNewsLoading) // eslint-disable-next-line
    const [noMoreData, setNoMoreData] = useState(false)
    const containerRef = useRef(null)
    const getHoursAgo = (timestamp) => {
        const time = moment(timestamp)
        const now = moment()
        const hoursDiff = now.diff(time, "hours")
        return `${hoursDiff} ${hoursDiff === 1 ? "hour" : "hours"} ago`
    }

    return (
        <div ref={containerRef} style={{ height: "670px" }}>
            <Typography
                sx={{
                    fontFamily: "Mona Sans",
                    fontSize: "18px",
                    fontWeight: 500,
                    lineHeight: "24px",
                    textAlign: "left",
                    color: "#1E1E1E",
                    pl: "15px",
                    pt: "20px"
                }}
            >
                Market News
            </Typography>

            {!loading && marketDetails?.length > 0 && (
                <List
                    sx={{
                        height: "calc(100% - 54px)",
                        overflowY: "auto",
                        width: "100%"
                    }}
                >
                    {marketDetails?.map((item, index) => (
                        <Grid
                            key={index}
                            onClick={() => window.open(item.url, "_blank")}
                            sx={{ cursor: "pointer" }}
                        >
                            <Grid
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    width: "100%",
                                    padding: 2
                                }}
                            >
                                <Grid
                                    display="flex"
                                    flexDirection="row"
                                    gap={1}
                                    justifyContent="space-between"
                                >
                                    <Grid
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column"
                                        }}
                                    >
                                        <Grid item>
                                            <Typography
                                                title={item?.source}
                                                sx={{
                                                    fontFamily: "Mona Sans",
                                                    fontSize: "16px",
                                                    fontWeight: 500,
                                                    lineHeight: "24px",
                                                    textAlign: "left"
                                                }}
                                            >
                                                {item?.source}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography
                                                title={item?.name}
                                                sx={{
                                                    fontFamily: " Mona Sans",
                                                    fontSize: "16px",
                                                    fontWeight: 400,
                                                    lineHeight: "24px",
                                                    textAlign: "left"
                                                }}
                                            >
                                                {item?.name}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        display="flex"
                                        flexDirection="row"
                                        alignItems="center"
                                        gap={1}
                                    >
                                        {(item?.overallSentimentLabel ===
                                            "Bearish" ||
                                            item?.overallSentimentLabel ===
                                            "Somewhat-Bearish") && (
                                                <ArrowDropDownIcon
                                                    sx={{
                                                        fontSize: "24px",
                                                        color: "red"
                                                    }}
                                                />
                                            )}
                                        {(item?.overallSentimentLabel ===
                                            "Bullish" ||
                                            item?.overallSentimentLabel ===
                                            "Somewhat-Bullish") && (
                                                <ArrowDropUpIcon
                                                    sx={{
                                                        fontSize: "24px",
                                                        color: "green"
                                                    }}
                                                />
                                            )}
                                        {item?.overallSentimentLabel ===
                                            "Neutral" && (
                                                <RemoveIcon
                                                    sx={{
                                                        fontSize: "18px",
                                                        color: "grey"
                                                    }}
                                                ></RemoveIcon>
                                            )}
                                        {item?.bannerImage && (
                                            <Grid>
                                                {/* bannerImage */}
                                                <img
                                                    style={{
                                                        width: "64px",
                                                        maxWidth: "64px",
                                                        height: "64px",
                                                        borderRadius: "8px",
                                                        alignItems: "right",
                                                        marginLeft: 1
                                                    }}
                                                    loading="lazy"
                                                    src={item?.bannerImage}
                                                    alt="images"
                                                />
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                                <Grid>
                                    <Typography
                                        sx={{
                                            fontFamily: "Mona Sans",
                                            fontSize: "16px",
                                            fontWeight: 400,
                                            lineHeight: "24px",
                                            textAlign: "left",
                                            color: "#777777"
                                        }}
                                    >
                                        {getHoursAgo(item?.timePublished)}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider />
                        </Grid>
                    ))}
                </List>
            )}

            {loading && (
                <div
                    style={{
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                        marginTop: 4
                    }}
                >
                    {" "}
                    <CircularProgress size={20} />{" "}
                </div>
            )}
            {marketDetails?.length === 0 && !loading && (
                <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{
                        textAlign: "center",
                        paddingTop: "50%"
                    }}
                >
                    No data available
                </Typography>
            )}
            {noMoreData && (
                <Typography variant="body2" color="textSecondary">
                    No more data
                </Typography>
            )}
        </div>
    )
}

export default MarketNews
