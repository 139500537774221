import { useFormikContext, useField } from "formik"
import { TextField, MenuItem, Chip, Box } from "@mui/material"

const SelectWrapper = ({
    name,
    size = "small",
    fullWidth = true,
    options,
    handleChange,
    sx,
    multiple = false,
    readOnly = false,
    ...props
}) => {
    const [field, meta] = useField(name)
    const { setFieldValue } = useFormikContext()

    const onChange = (event) => {
        const value = event.target.value
        handleChange?.(value, setFieldValue)
        setFieldValue(name, value)
    }

    const selectConfig = {
        ...field,
        ...props,
        select: true,
        fullWidth,
        size,
        onChange,
        SelectProps: {
            multiple,
            ...(multiple
                ? {
                      renderValue: (selected) => {
                          const renderList = options.filter((opt) =>
                              selected.includes(opt.value)
                          )

                          return (
                              <Box
                                  sx={{
                                      display: "flex",
                                      flexWrap: "wrap",
                                      gap: 0.5
                                  }}
                              >
                                  {renderList.map(({ label, value }) => (
                                      <Chip  key={value} label={label} />
                                  ))}
                              </Box>
                          )
                      }
                  }
                : {})
        },
        InputProps: {
            readOnly
        }
    }

    if (meta && meta.error && meta.touched) {
        selectConfig.error = true
        selectConfig.helperText = meta.error
    }

    return (
        <TextField
            sx={{
                "& .MuiOutlinedInput-root": {
                    "&:hover fieldset": {
                        borderColor: "#7450da"
                    },
                    "&.Mui-focused fieldset": {
                        borderColor: "#7450da"
                    }
                },
                ...sx
            }}
            {...selectConfig}
        >
            <MenuItem disabled>&mdash; Select Any &mdash;</MenuItem>
            {options.map((option, index) => (
                <MenuItem key={index} value={option.value}>
                    {option.label}
                </MenuItem>
            ))}
        </TextField>
    )
}

export default SelectWrapper
