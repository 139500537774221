import React, { useState, useEffect } from "react" // eslint-disable-next-line
import {
    Box,
    Grid,
    Button,
    Container,
    Typography,
    Chip,
    IconButton,
    TextField
} from "@mui/material"
import Tab from "@mui/material/Tab"
import TabContext from "@mui/lab/TabContext"
import TabList from "@mui/lab/TabList"
import TabPanel from "@mui/lab/TabPanel"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormControl from "@mui/material/FormControl"
import Modal from "@mui/material/Modal"
import { ReactComponent as Active } from "../../../../../theme/images/Active.svg"
import { ReactComponent as Edit } from "../../../../../theme/images/Edit.svg"
import { ReactComponent as Delete } from "../../../../../theme/images/Delete.svg"
import {
    getAssetHistory,
    updateAssetHistory,
    getClientAssetHistory,
    updateClientAssetHistory,
    updateStatementCommentProspect,
    updateStatementCommentClient
} from "../../../../../services/index"
import moment from "moment"
import { useParams } from "react-router-dom"
import Pagination from "@mui/material/Pagination"
import { toast } from "react-toastify"

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: 350, sm: 550, md: 750, lg: 750 },
    height: { xs: 450, sm: 550 },
    bgcolor: "background.paper",
    border: "1px solid #000",
    boxShadow: 24,
    p: 2,
    overflowY: { xs: "scroll", sm: "scroll" }
}

function Institutions({
    assetdetails,
    financialCategories,
    lifestyleCategories,
    producttype,
    handleDelinkBankDetails,
    profileType,
    sendRefreshIdToParent,
    readOnly
}) {
    const [open, setOpen] = React.useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [assethistory, setAssethistory] = useState([]) // eslint-disable-next-line
    const [totalRecord, setTotalRecord] = useState()
    const [page, setPage] = useState(0)
    const [totalPages, setTotalPages] = useState(1)
    const [newvalue, setNewvalue] = useState(0)
    const [assetid, setAssetid] = useState(0)
    const [comment, setComment] = useState("")
    const [editcomment, setEditcomment] = useState("")
    const [file, setFile] = useState([])
    const [filename, setFilename] = useState("")
    const [editIndex, setEditIndex] = useState()
    const { id } = useParams()
    const user = JSON.parse(localStorage.getItem("user"))
    const [firstvalue, setFirstvalue] = React.useState(1)
    const [secondvalue, setSecondvalue] = React.useState(1)
    const [radiovalue, setRadiovalue] = React.useState("")
    const [financialcategories, setFinancialcategories] = useState([])
    const [lifestylecategories, setLifestylecategories] = useState([])
    useEffect(() => {
        setRadiovalue("FINANCIAL PRODUCTS")
        setFinancialcategories(financialCategories) // eslint-disable-next-line
    }, [financialCategories])
    const handleFirstChange = (event, newValue) => {
        setFirstvalue(newValue)
    }
    const handleSecondChange = (event, newValue) => {
        setSecondvalue(newValue)
    }
    const handleRadioChange = (event) => {
        setRadiovalue(event.target.value)
        if (event.target.value === "FINANCIAL PRODUCTS") {
            setFinancialcategories(financialCategories)
        } else {
            setLifestylecategories(lifestyleCategories)
        }
    }
    const handleOpen = (id) => {
        setOpen(true)
        setAssetid(id)
        if (profileType === "client") {
            const obj = {
                pageNo: page,
                pageSize: 5,
                assetId: id
            }
            getClientAssetHistory(obj, "clientprofile").then((response) => {
                if (response?.data?.status) {
                    setAssethistory(response?.data?.body)
                    setTotalRecord(response?.data?.totalRecordCount)
                    setTotalPages(response?.data?.totalPages)
                } else {
                    setAssethistory([])
                    setTotalRecord(0)
                    setTotalPages(0)
                }
            })
        } else {
            const obj = {
                pageNo: page,
                pageSize: 5,
                assetId: id
            }
            getAssetHistory(obj).then((response) => {
                if (response?.data?.status) {
                    setAssethistory(response?.data?.body)
                    setTotalRecord(response?.data?.totalRecordCount)
                    setTotalPages(response?.data?.totalPages)
                } else {
                    setAssethistory([])
                    setTotalRecord(0)
                    setTotalPages(0)
                }
            })
        }
    }
    const handleClose = () => setOpen(false)
    const handleClickEdit = (index, commentvalue) => {
        setEditIndex(index)
        setEditcomment(commentvalue)
        setIsEdit(true)
    }
    const handleChange = (event, value) => {
        setPage(value)
        if (profileType === "client") {
            const obj = {
                pageNo: value - 1,
                pageSize: 5,
                assetId: assetid
            }
            getClientAssetHistory(obj, "clientprofile").then((response) => {
                if (response?.data?.status) {
                    setAssethistory(response?.data?.body)
                    setTotalRecord(response?.data?.totalRecordCount)
                    setTotalPages(response?.data?.totalPages)
                } else {
                    setAssethistory([])
                    setTotalRecord(0)
                    setTotalPages(0)
                }
            })
        } else {
            const obj = {
                pageNo: value - 1,
                pageSize: 5,
                assetId: assetid
            }
            getAssetHistory(obj).then((response) => {
                if (response?.data?.status) {
                    setAssethistory(response?.data?.body)
                    setTotalRecord(response?.data?.totalRecordCount)
                    setTotalPages(response?.data?.totalPages)
                } else {
                    setAssethistory([])
                    setTotalRecord(0)
                    setTotalPages(0)
                }
            })
        }
    }

    const handleClickCloseEdit = (assetid) => {
        const obj = {
            id: assetid,
            comment: editcomment
        }
        if (profileType === "client") {
            updateStatementCommentClient(obj, "clientprofile").then(
                (response) => {
                    if (response?.data?.status) {
                        setOpen(false)
                        setIsEdit(false)
                        toast.success(response?.data?.statusMessage)
                    } else {
                        setOpen(true)
                        setIsEdit(true)
                        toast.error(response?.data?.errorMessage)
                    }
                }
            )
        } else {
            updateStatementCommentProspect(obj).then((response) => {
                if (response?.data?.status) {
                    setOpen(false)
                    setIsEdit(false)
                    toast.success(response?.data?.statusMessage)
                } else {
                    setOpen(true)
                    setIsEdit(true)
                    toast.error(response?.data?.errorMessage)
                }
            })
        }
    }
    const handleDeleteFile = () => {
        setFile([])
        setFilename("")
    }
    const handleFileChange = (event) => {
        const fileName = event.target.files?.[0].name
        setFilename(fileName)
        setFile(event.target.files[0])
    }
    const handleSaveAssetHistory = () => {
        const obj = {
            assetId: assetid,
            ...(profileType === "client" && { clientId: Number(atob(id)) }),
            ...(profileType === "prospect" && { prospectId: Number(atob(id)) }),
            rmId: user?.userId,
            currentValue: assethistory?.[0]?.currentValue,
            newValue: newvalue,
            date: moment().format().split("T")?.[0],
            comment: comment,
            entityId: atob(localStorage.getItem("entityId"))
        }
        let formData = new FormData()
        formData.append("bankAssetHistoryRequest", JSON.stringify(obj))
        formData.append("uploadStatement", file)
        if (profileType === "client") {
            updateClientAssetHistory(formData, "clientprofile").then(
                (response) => {
                    if (response?.data?.status) {
                        setOpen(false)
                        setNewvalue(0)
                        setComment("")
                        setFilename("")
                    } else {
                        setOpen(true)
                    }
                }
            )
        } else {
            updateAssetHistory(formData).then((response) => {
                if (response?.data?.status) {
                    setOpen(false)
                    setNewvalue(0)
                    setComment("")
                    setFilename("")
                } else {
                    setOpen(true)
                }
            })
        }
    }

    const handleRefresh = (itemid) => {
        sendRefreshIdToParent(itemid)
    }

    if (open) {
        return (
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography
                        id="modal-modal-title"
                        variant="h5"
                        component="h2"
                        sx={{ fontWeight: 600, mb: 2 }}
                    >
                        Upload statement
                    </Typography>
                    <Box
                        sx={{
                            background: "#F1F1F1 0% 0% no-repeat padding-box",
                            border: "1px solid #D5D5D5",
                            width: { xs: 300, sm: 500, md: 700, lg: 700 },
                            m: 0.5
                        }}
                    >
                        <Grid container sx={{ p: 1 }}>
                            <Grid item xs={12} sm={12} md={2} lg={2}>
                                <Typography>Current Value</Typography>
                                <TextField
                                    size="small"
                                    type="number"
                                    step="any"
                                    sx={{
                                        width: "80%",
                                        gridColumn: "span 2",
                                        height: "40px",
                                        "& input::placeholder": {
                                            textOverflow: "ellipsis !important",
                                            color: "#363636 !important",
                                            opacity: 1
                                        },
                                        "& .MuiInputBase-root": {
                                            fontFamily:
                                                "Futura Md BT !important",
                                            height: "40px !important",
                                            maxWidth: "auto",
                                            background:
                                                "#FFFFFF 0% 0% no-repeat padding-box",
                                            borderRadius: "8px"
                                        }
                                    }}
                                    value={assethistory?.[0]?.currentValue}
                                    // onChange={(event) => setAccountno(event.target.value)}
                                    id={"currentvalue"}
                                    name={"currentvalue"}
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={2} lg={2}>
                                <Typography>New Value</Typography>
                                <TextField
                                    size="small"
                                    step="any"
                                    type="number"
                                    sx={{
                                        width: "80%",
                                        height: "40px",
                                        gridColumn: "span 2",
                                        "& input::placeholder": {
                                            textOverflow: "ellipsis !important",
                                            opacity: 1,
                                            color: "#363636 !important"
                                        },
                                        "& .MuiInputBase-root": {
                                            fontFamily:
                                                "Futura Md BT !important",
                                            maxWidth: "auto",
                                            height: "40px !important",
                                            background:
                                                "#FFFFFF 0% 0% no-repeat padding-box",
                                            borderRadius: "8px"
                                        }
                                    }}
                                    inputProps={{ min: 0 }}
                                    onKeyDown={(e) => {
                                        if (e.key === "-") {
                                            e.preventDefault()
                                        }
                                    }}
                                    onChange={(event) =>
                                        setNewvalue(event.target.value)
                                    }
                                    value={newvalue}
                                    name={"newvalue"}
                                    id={"newvalue"}
                                    placeholder=""
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={3} lg={3}>
                                <Typography>Comment</Typography>
                                <TextField
                                    size="small"
                                    type="text"
                                    step="any"
                                    sx={{
                                        width: "90%",
                                        gridColumn: "span 2",
                                        height: "40px",
                                        "& input::placeholder": {
                                            textOverflow: "ellipsis !important",
                                            color: "#363636 !important",
                                            opacity: 1
                                        },
                                        "& .MuiInputBase-root": {
                                            fontFamily:
                                                "Futura Md BT !important",
                                            height: "40px !important",
                                            maxWidth: "auto",
                                            background:
                                                "#FFFFFF 0% 0% no-repeat padding-box",
                                            borderRadius: "8px"
                                        }
                                    }}
                                    value={comment}
                                    onChange={(event) =>
                                        setComment(event.target.value)
                                    }
                                    id={"comment"}
                                    name={"comment"}
                                    placeholder="Enter the comment"
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={3}
                                lg={3}
                                sx={{
                                    textAlign: {
                                        xs: "left",
                                        sm: "left",
                                        md: "center",
                                        lg: "center"
                                    },
                                    mt: 1
                                }}
                            >
                                <Typography>Attachment</Typography>
                                {/* <Button style={{color: "white", width: "120px", height: "25px", background: "#6A3BE2 0% 0% no-repeat padding-box", boxShadow: "1px 8px 29px #695F9724", borderRadius: "8px", opacity: 1, fontSize: 10}}>{"Upload Statement"}</Button> */}
                                {filename === "" && (
                                    <div
                                        style={{
                                            width: "120px",
                                            height: "25px",
                                            background:
                                                "#6A3BE2 0% 0% no-repeat padding-box",
                                            boxShadow: "1px 8px 29px #695F9724",
                                            borderRadius: "8px",
                                            opacity: 1,
                                            marginLeft: "25px"
                                        }}
                                    >
                                        <label
                                            className="custom-file-upload"
                                            style={{
                                                color: "white",
                                                fontSize: 10,
                                                cursor: "pointer"
                                            }}
                                        >
                                            <input
                                                type="file"
                                                accept=".pdf, .epdf, .xlsx"
                                                onChange={(event) =>
                                                    handleFileChange(event)
                                                }
                                                style={{ display: "none" }}
                                            />
                                            Upload statement
                                        </label>
                                    </div>
                                )}
                                {filename !== "" && (
                                    <div>
                                        <Typography
                                            sx={{ color: "#858585", mb: 1 }}
                                        >
                                            {filename}
                                        </Typography>
                                        <IconButton>
                                            <Delete
                                                onClick={handleDeleteFile}
                                            />
                                        </IconButton>
                                    </div>
                                )}
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={2}
                                lg={2}
                                sx={{ textAlign: "center", mt: 3 }}
                            >
                                <Button
                                    style={{
                                        color: "white",
                                        width: "100px",
                                        height: "25px",
                                        background:
                                            "#6A3BE2 0% 0% no-repeat padding-box",
                                        boxShadow: "1px 8px 29px #695F9724",
                                        borderRadius: "8px",
                                        opacity: 1,
                                        fontSize: 10
                                    }}
                                    onClick={handleSaveAssetHistory}
                                >
                                    {"Save"}
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                    <Typography sx={{ mt: 3, fontWeight: 600 }}>
                        Upload History
                    </Typography>
                    <Box
                        sx={{
                            height: "300px",
                            overflowX: {
                                xs: "scroll",
                                sm: "scroll",
                                md: "hidden",
                                lg: "hidden"
                            },
                            overflowY: "scroll"
                        }}
                    >
                        {assethistory?.map((asset, index) => {
                            return (
                                <Box
                                    sx={{
                                        background:
                                            "#F1F1F1 0% 0% no-repeat padding-box",
                                        border: "1px solid #D5D5D5",
                                        width: 700,
                                        m: 0.5
                                    }}
                                >
                                    <Grid container sx={{ p: 1 }}>
                                        <Grid item xs={2.5}>
                                            <Typography
                                                sx={{ color: "#858585", mb: 1 }}
                                            >
                                                Date
                                            </Typography>
                                            <Typography>
                                                {moment(asset?.date).format(
                                                    "DD-MM-yyyy"
                                                )}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2.5}>
                                            <Typography
                                                sx={{ color: "#858585", mb: 1 }}
                                            >
                                                Current Value $
                                            </Typography>
                                            <Typography>
                                                {asset?.currentValue}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2.5}>
                                            <Typography
                                                sx={{ color: "#858585", mb: 1 }}
                                            >
                                                New Value $
                                            </Typography>
                                            <Typography>
                                                {asset?.newValue}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4.5}>
                                            <Typography
                                                sx={{
                                                    color: "#858585",
                                                    mb: 0.5
                                                }}
                                            >
                                                Comment
                                            </Typography>
                                            {isEdit === true &&
                                                editIndex === index && (
                                                    <TextField
                                                        size="small"
                                                        type="text"
                                                        step="any"
                                                        sx={{
                                                            width: "80%",
                                                            gridColumn:
                                                                "span 2",
                                                            height: "30px",
                                                            "& input::placeholder":
                                                            {
                                                                textOverflow:
                                                                    "ellipsis !important",
                                                                color: "#363636 !important",
                                                                opacity: 1
                                                            },
                                                            "& .MuiInputBase-root":
                                                            {
                                                                fontFamily:
                                                                    "Futura Md BT !important",
                                                                height: "40px !important",
                                                                maxWidth:
                                                                    "auto",
                                                                background:
                                                                    "#FFFFFF 0% 0% no-repeat padding-box",
                                                                borderRadius:
                                                                    "8px"
                                                            }
                                                        }}
                                                        value={editcomment}
                                                        onChange={(event) =>
                                                            setEditcomment(
                                                                event.target
                                                                    .value
                                                            )
                                                        }
                                                        id={"comment"}
                                                        name={"comment"}
                                                        placeholder="Enter the comment"
                                                    />
                                                )}
                                            {(editIndex !== index ||
                                                isEdit === false) && (
                                                    <Grid container>
                                                        <Grid item xs={9}>
                                                            <Typography
                                                                sx={{
                                                                    textOverflow:
                                                                        "ellipsis",
                                                                    overflow:
                                                                        "hidden",
                                                                    whiteSpace:
                                                                        "nowrap"
                                                                }}
                                                            >
                                                                {asset?.comment}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <IconButton
                                                                onClick={() =>
                                                                    handleClickEdit(
                                                                        index,
                                                                        asset?.comment
                                                                    )
                                                                }
                                                            >
                                                                <Edit />
                                                            </IconButton>
                                                        </Grid>
                                                    </Grid>
                                                )}
                                            {isEdit === true &&
                                                editIndex === index && (
                                                    <IconButton
                                                        onClick={() =>
                                                            handleClickCloseEdit(
                                                                asset.id
                                                            )
                                                        }
                                                    >
                                                        <Active fill="#66c824" />
                                                    </IconButton>
                                                )}
                                            {/* {isEdit === false && } */}
                                        </Grid>
                                    </Grid>
                                </Box>
                            )
                        })}
                    </Box>
                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor: "white",
                            color: "#7A86A1",
                            border: "1px solid #7A86A1",
                            width: "100px",
                            height: "32px",
                            borderRadius: "6px"
                        }}
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                    {
                        <div style={{ float: "right" }}>
                            <Pagination
                                sx={{
                                    width: "120px",
                                    height: "25px",
                                    opacity: "1",
                                    "& .MuiPaginationItem-root.Mui-selected ": {
                                        backgroundColor: "black !important",
                                        color: "white !important"
                                    }
                                }}
                                onChange={handleChange}
                                page={page + 1}
                                count={totalPages}
                            />
                        </div>
                    }
                </Box>
            </Modal>
        )
    }
    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    p: 1,
                    mt: 2
                }}
            >
                <Typography variant="h5">My Institutions / Assets</Typography>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    p: 1,
                    mt: 0.5
                }}
            >
                <FormControl>
                    <RadioGroup
                        row
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={radiovalue}
                        onChange={handleRadioChange}
                    >
                        {producttype?.map((asset, index) => {
                            return (
                                <FormControlLabel
                                    key={index}
                                    value={asset.description}
                                    control={
                                        <Radio
                                            className="familyRadio"
                                            sx={{
                                                color: "#4CAF50",
                                                "&.Mui-checked": {
                                                    color: "#66BB6A"
                                                }
                                            }}
                                        />
                                    }
                                    label={asset.description}
                                />
                            )
                        })}
                    </RadioGroup>
                </FormControl>
            </Box>
            <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
                {radiovalue === "FINANCIAL PRODUCTS" && (
                    <TabContext value={firstvalue}>
                        <Box sx={{ borderColor: "#000000" }}>
                            <TabList
                                onChange={handleFirstChange}
                                variant="scrollable"
                                allowScrollButtonsMobile={true}
                                scrollButtons="auto"
                                aria-label="scrollable auto tabs example"
                                sx={{
                                    ".MuiTabs-scrollButtons.Mui-disabled": {
                                        opacity: 0.3,
                                        backgroundColor: "rgb(106, 59, 226)",
                                        borderRadius: "20px",
                                        color: "#ffffff",
                                        width: "30px",
                                        height: "30px",
                                        margin: "5px"
                                    },
                                    ".MuiTabs-scrollButtons": {
                                        opacity: 1,
                                        backgroundColor: "rgb(106, 59, 226)",
                                        borderRadius: "20px",
                                        color: "#ffffff",
                                        width: "30px",
                                        height: "30px",
                                        margin: "5px"
                                    },
                                    ".Mui-selected": {
                                        color: "#FFFFFF !important",
                                        backgroundColor: "#66C824",
                                        borderRadius: "5px"
                                    }
                                }}
                            >
                                {financialcategories?.map((item, index) => {
                                    return (
                                        <Tab
                                            key={index}
                                            sx={{
                                                border: "1px solid #F0EDED",
                                                borderRadius: "5px",
                                                color: "#000000",
                                                borderBottom: "",
                                                ".MuiButtonBase-root-MuiTab-root.Mui-selected":
                                                {
                                                    color: "#ffffff",
                                                    backgroundColor:
                                                        "#4CAF50"
                                                }
                                            }}
                                            label={item.description}
                                            value={index + 1}
                                        />
                                    )
                                })}
                            </TabList>
                        </Box>
                        {financialcategories?.map((element, index) => {
                            return (
                                <TabPanel key={"fincat" + index} value={index + 1}>
                                    <Grid container>
                                        <Grid item xs={12} sx={{ p: 1 }}>
                                            {assetdetails?.find(
                                                (val) =>
                                                    val.category ===
                                                    element.description
                                            )?.bankDetailsDtoList !== null &&
                                                assetdetails
                                                    ?.find(
                                                        (val) =>
                                                            val.category ===
                                                            element.description
                                                    )
                                                    ?.bankDetailsDtoList?.map(
                                                        (dtoval, dtoindex) => {
                                                            return (
                                                                <Container
                                                                    key={
                                                                        dtoindex
                                                                    }
                                                                    sx={{
                                                                        background:
                                                                            "#F0EDED 0% 0% no-repeat padding-box",
                                                                        borderRadius:
                                                                            "8px",
                                                                        opacity: 1,
                                                                        p: 2,
                                                                        mb: 2
                                                                    }}
                                                                >
                                                                    <Grid
                                                                        container
                                                                    >
                                                                        <Grid
                                                                            item
                                                                            xs={
                                                                                12
                                                                            }
                                                                            sm={
                                                                                12
                                                                            }
                                                                            md={
                                                                                3
                                                                            }
                                                                            lg={
                                                                                3
                                                                            }
                                                                        >
                                                                            <Grid
                                                                                container
                                                                            >
                                                                                <Grid
                                                                                    item
                                                                                    xs={
                                                                                        12
                                                                                    }
                                                                                >
                                                                                    <Chip
                                                                                        size="small"
                                                                                        label={
                                                                                            dtoval?.type
                                                                                        }
                                                                                        sx={{
                                                                                            backgroundColor:
                                                                                                dtoval?.type ===
                                                                                                    "Outside Kairos"
                                                                                                    ? "#E02020"
                                                                                                    : "#66C824",
                                                                                            color: "#ffffff",
                                                                                            borderRadius:
                                                                                                "5px",
                                                                                            height: 15
                                                                                        }}
                                                                                    />
                                                                                    <Typography
                                                                                        variant="h5"
                                                                                        sx={{
                                                                                            fontWeight: 700
                                                                                        }}
                                                                                    >
                                                                                        {dtoval.bankName ===
                                                                                            "Others" ||
                                                                                            dtoval.bankName ===
                                                                                            null
                                                                                            ? dtoval.assetName
                                                                                            : dtoval.bankName}
                                                                                    </Typography>
                                                                                </Grid>
                                                                                <Grid
                                                                                    item
                                                                                    xs={
                                                                                        12
                                                                                    }
                                                                                >
                                                                                    {dtoval.primaryCategoryId ===
                                                                                        null ? (
                                                                                        <Typography
                                                                                            variant="subtitle2"
                                                                                            sx={{
                                                                                                fontWeight: 600
                                                                                            }}
                                                                                        >
                                                                                            Ending
                                                                                            with{" "}
                                                                                            <Typography
                                                                                                variant="caption"
                                                                                                sx={{
                                                                                                    color: "#979797"
                                                                                                }}
                                                                                            >
                                                                                                {dtoval?.bankAccountNumber?.substr(
                                                                                                    -4
                                                                                                )}
                                                                                            </Typography>
                                                                                        </Typography>
                                                                                    ) : (
                                                                                        <Typography variant="subtitle2">
                                                                                            {
                                                                                                dtoval
                                                                                                    .primaryCategoryId
                                                                                                    ?.description
                                                                                            }
                                                                                        </Typography>
                                                                                    )}
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                        <Grid
                                                                            item
                                                                            xs={
                                                                                12
                                                                            }
                                                                            sm={
                                                                                12
                                                                            }
                                                                            md={
                                                                                2
                                                                            }
                                                                            lg={
                                                                                2
                                                                            }
                                                                            sx={{
                                                                                mt: 1
                                                                            }}
                                                                        >
                                                                            <Grid
                                                                                container
                                                                            >
                                                                                <Grid
                                                                                    item
                                                                                    xs={
                                                                                        12
                                                                                    }
                                                                                >
                                                                                    <Typography variant="subtitle2">
                                                                                        {dtoval.bankName ===
                                                                                            "Others" ||
                                                                                            dtoval.bankName ===
                                                                                            null
                                                                                            ? "Purchase Date"
                                                                                            : "Bank Code"}
                                                                                    </Typography>
                                                                                </Grid>
                                                                                <Grid
                                                                                    item
                                                                                    xs={
                                                                                        12
                                                                                    }
                                                                                >
                                                                                    <Typography
                                                                                        variant="h6"
                                                                                        sx={{
                                                                                            fontWeight: 600
                                                                                        }}
                                                                                    >
                                                                                        {dtoval.bankName ===
                                                                                            "Others" ||
                                                                                            dtoval.bankName ===
                                                                                            null
                                                                                            ? moment(
                                                                                                dtoval.dateOfRequest
                                                                                            ).format(
                                                                                                "DD/MM/YYYY"
                                                                                            )
                                                                                            : dtoval?.bankCode}
                                                                                    </Typography>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                        <Grid
                                                                            item
                                                                            xs={
                                                                                12
                                                                            }
                                                                            sm={
                                                                                12
                                                                            }
                                                                            md={
                                                                                2
                                                                            }
                                                                            lg={
                                                                                2
                                                                            }
                                                                            sx={{
                                                                                mt: 1
                                                                            }}
                                                                        >
                                                                            <Grid
                                                                                container
                                                                            >
                                                                                <Grid
                                                                                    item
                                                                                    xs={
                                                                                        12
                                                                                    }
                                                                                >
                                                                                    <Typography variant="subtitle2">
                                                                                        Synchup
                                                                                        Type
                                                                                    </Typography>
                                                                                </Grid>
                                                                                <Grid
                                                                                    item
                                                                                    xs={
                                                                                        12
                                                                                    }
                                                                                >
                                                                                    <Typography
                                                                                        variant="h6"
                                                                                        sx={{
                                                                                            fontSize:
                                                                                                "13px",
                                                                                            color:
                                                                                                dtoval.synchupType ===
                                                                                                    "Offline"
                                                                                                    ? "#E02020"
                                                                                                    : "#1DB954"
                                                                                        }}
                                                                                    >
                                                                                        {
                                                                                            dtoval?.synchupType
                                                                                        }
                                                                                    </Typography>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                        <Grid
                                                                            item
                                                                            xs={
                                                                                12
                                                                            }
                                                                            sm={
                                                                                12
                                                                            }
                                                                            md={
                                                                                1
                                                                            }
                                                                            lg={
                                                                                1
                                                                            }
                                                                            display={{
                                                                                md: "block",
                                                                                lg: "block"
                                                                            }}
                                                                            sx={{
                                                                                mt: 1
                                                                            }}
                                                                        >
                                                                            <Grid
                                                                                container
                                                                            >
                                                                                <Grid
                                                                                    item
                                                                                    xs={
                                                                                        12
                                                                                    }
                                                                                >
                                                                                    <Typography variant="subtitle2">
                                                                                        Status
                                                                                    </Typography>
                                                                                </Grid>
                                                                                <Grid
                                                                                    item
                                                                                    xs={
                                                                                        12
                                                                                    }
                                                                                >
                                                                                    <Typography
                                                                                        variant="h6"
                                                                                        sx={{
                                                                                            fontSize:
                                                                                                "13px",
                                                                                            color:
                                                                                                dtoval?.status ===
                                                                                                    "Pending"
                                                                                                    ? "#E02020"
                                                                                                    : "#1DB954"
                                                                                        }}
                                                                                    >
                                                                                        {
                                                                                            dtoval?.status
                                                                                        }
                                                                                    </Typography>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                        {dtoval?.status !==
                                                                            "pending" && (
                                                                                <Grid
                                                                                    item
                                                                                    xs={
                                                                                        12
                                                                                    }
                                                                                    sm={
                                                                                        12
                                                                                    }
                                                                                    md={
                                                                                        2
                                                                                    }
                                                                                    lg={
                                                                                        2
                                                                                    }
                                                                                    display={{
                                                                                        md: "block",
                                                                                        lg: "block"
                                                                                    }}
                                                                                >
                                                                                    <Grid
                                                                                        container
                                                                                    >
                                                                                        {(dtoval.bankName ===
                                                                                            "Others" ||
                                                                                            dtoval.bankName ===
                                                                                            null) && (
                                                                                                <Grid
                                                                                                    item
                                                                                                    xs={
                                                                                                        12
                                                                                                    }
                                                                                                >
                                                                                                    <Typography variant="subtitle2">
                                                                                                        Current
                                                                                                        Value
                                                                                                        ($)
                                                                                                    </Typography>
                                                                                                </Grid>
                                                                                            )}
                                                                                        <Grid
                                                                                            item
                                                                                            xs={
                                                                                                12
                                                                                            }
                                                                                        >
                                                                                            {dtoval.balance !==
                                                                                                null && (
                                                                                                    <Typography variant="h5">{`$ ${dtoval.balance}`}</Typography>
                                                                                                )}
                                                                                        </Grid>
                                                                                        <Grid
                                                                                            item
                                                                                            xs={
                                                                                                12
                                                                                            }
                                                                                        >
                                                                                            {dtoval.balance !==
                                                                                                null && (
                                                                                                    <Typography
                                                                                                        variant="subtitle2"
                                                                                                        sx={{
                                                                                                            color: "#8A8993"
                                                                                                        }}
                                                                                                    >
                                                                                                        Last
                                                                                                        updated:
                                                                                                        Just
                                                                                                        now
                                                                                                    </Typography>
                                                                                                )}
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            )}
                                                                        {dtoval?.status !==
                                                                            "pending" && (
                                                                                <Grid
                                                                                    item
                                                                                    xs={
                                                                                        12
                                                                                    }
                                                                                    sm={
                                                                                        12
                                                                                    }
                                                                                    md={
                                                                                        2
                                                                                    }
                                                                                    lg={
                                                                                        2
                                                                                    }
                                                                                >
                                                                                    {!readOnly && (
                                                                                        <Grid
                                                                                            container
                                                                                        >
                                                                                            <Grid
                                                                                                item
                                                                                                sm={
                                                                                                    12
                                                                                                }
                                                                                                xs={
                                                                                                    12
                                                                                                }
                                                                                                sx={{
                                                                                                    p: 0.25
                                                                                                }}
                                                                                            >
                                                                                                {dtoval.synchupType ===
                                                                                                    "Offline" ? (
                                                                                                    <Button
                                                                                                        style={{
                                                                                                            color: "white",
                                                                                                            width: "130px",
                                                                                                            height: "25px",
                                                                                                            background:
                                                                                                                "#6A3BE2 0% 0% no-repeat padding-box",
                                                                                                            boxShadow:
                                                                                                                "1px 8px 29px #695F9724",
                                                                                                            borderRadius:
                                                                                                                "8px",
                                                                                                            opacity: 1
                                                                                                        }}
                                                                                                        onClick={() =>
                                                                                                            handleOpen(
                                                                                                                dtoval.id
                                                                                                            )
                                                                                                        }
                                                                                                    >
                                                                                                        {
                                                                                                            "Re-evaluate"
                                                                                                        }
                                                                                                    </Button>
                                                                                                ) : (
                                                                                                    <Button
                                                                                                        style={{
                                                                                                            color: "white",
                                                                                                            width: "130px",
                                                                                                            height: "25px",
                                                                                                            background:
                                                                                                                "#6A3BE2 0% 0% no-repeat padding-box",
                                                                                                            boxShadow:
                                                                                                                "1px 8px 29px #695F9724",
                                                                                                            borderRadius:
                                                                                                                "8px",
                                                                                                            opacity: 1
                                                                                                        }}
                                                                                                        onClick={() =>
                                                                                                            handleRefresh(
                                                                                                                dtoval.id
                                                                                                            )
                                                                                                        }
                                                                                                    >
                                                                                                        {
                                                                                                            "Refresh"
                                                                                                        }
                                                                                                    </Button>
                                                                                                )}
                                                                                            </Grid>
                                                                                            <Grid
                                                                                                item
                                                                                                sm={
                                                                                                    12
                                                                                                }
                                                                                                xs={
                                                                                                    12
                                                                                                }
                                                                                                sx={{
                                                                                                    p: 0.25
                                                                                                }}
                                                                                            >
                                                                                                <Button
                                                                                                    style={{
                                                                                                        color: "#FFFFFF",
                                                                                                        width: "130px",
                                                                                                        height: "25px",
                                                                                                        background:
                                                                                                            "#000000 0% 0% no-repeat padding-box",
                                                                                                        boxShadow:
                                                                                                            "1px 8px 29px #695F9724",
                                                                                                        border: "8px",
                                                                                                        borderRadius:
                                                                                                            "8px",
                                                                                                        opacity: 1
                                                                                                    }}
                                                                                                    onClick={() =>
                                                                                                        handleDelinkBankDetails(
                                                                                                            dtoval.id,
                                                                                                            dtoval.bankName,
                                                                                                            dtoval.bankAccountNumber
                                                                                                        )
                                                                                                    }
                                                                                                >
                                                                                                    De-Link
                                                                                                </Button>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    )}
                                                                                </Grid>
                                                                            )}
                                                                    </Grid>
                                                                </Container>
                                                            )
                                                        }
                                                    )}
                                            {assetdetails?.find(
                                                (val) =>
                                                    val.category ===
                                                    element.description
                                            )?.bankDetailsDtoList === null && (
                                                    <Container
                                                        sx={{
                                                            background:
                                                                "#F0EDED 0% 0% no-repeat padding-box",
                                                            borderRadius: "8px",
                                                            opacity: 1,
                                                            p: 2,
                                                            mb: 2
                                                        }}
                                                    >
                                                        <Typography>
                                                            No records found
                                                        </Typography>
                                                    </Container>
                                                )}
                                        </Grid>
                                    </Grid>
                                </TabPanel>
                            )
                        })}
                    </TabContext>
                )}
                {radiovalue === "LIFESTYLE PRODUCTS" && (
                    <TabContext value={secondvalue}>
                        <Box sx={{ borderColor: "#000000" }}>
                            <TabList
                                onChange={handleSecondChange}
                                variant="scrollable"
                                allowScrollButtonsMobile={true}
                                scrollButtons="auto"
                                aria-label="scrollable auto tabs example"
                                sx={{
                                    ".MuiTabs-scrollButtons.Mui-disabled": {
                                        opacity: 0.3,
                                        backgroundColor: "rgb(106, 59, 226)",
                                        borderRadius: "20px",
                                        color: "#ffffff",
                                        width: "30px",
                                        height: "30px",
                                        margin: "5px"
                                    },
                                    ".MuiTabs-scrollButtons": {
                                        opacity: 1,
                                        backgroundColor: "rgb(106, 59, 226)",
                                        borderRadius: "20px",
                                        color: "#ffffff",
                                        width: "30px",
                                        height: "30px",
                                        margin: "5px"
                                    },
                                    ".Mui-selected": {
                                        color: "#FFFFFF !important",
                                        backgroundColor: "#66C824",
                                        borderRadius: "5px"
                                    }
                                }}
                            >
                                {lifestylecategories?.map((item, index) => {
                                    return (
                                        <Tab
                                            key={index}
                                            sx={{
                                                border: "1px solid #F0EDED",
                                                borderRadius: "5px",
                                                color: "#000000",
                                                ".MuiButtonBase-root-MuiTab-root.Mui-selected":
                                                {
                                                    color: "#ffffff",
                                                    backgroundColor:
                                                        "#4CAF50"
                                                }
                                            }}
                                            label={item.description}
                                            value={index + 1}
                                        />
                                    )
                                })}
                            </TabList>
                        </Box>
                        {lifestylecategories?.map((catitem, index) => {
                            return (
                                <TabPanel value={index + 1}>
                                    <Grid container>
                                        <Grid item xs={12} sx={{ p: 1 }}>
                                            {assetdetails?.find(
                                                (val) =>
                                                    val.category ===
                                                    catitem.description
                                            )?.bankDetailsDtoList !== null &&
                                                assetdetails
                                                    ?.find(
                                                        (val) =>
                                                            val.category ===
                                                            catitem.description
                                                    )
                                                    ?.bankDetailsDtoList?.map(
                                                        (
                                                            dtovalue,
                                                            dtoindex
                                                        ) => {
                                                            return (
                                                                <Container
                                                                    key={
                                                                        dtoindex
                                                                    }
                                                                    sx={{
                                                                        background:
                                                                            "#F0EDED 0% 0% no-repeat padding-box",
                                                                        borderRadius:
                                                                            "8px",
                                                                        opacity: 1,
                                                                        p: 2,
                                                                        mb: 2
                                                                    }}
                                                                >
                                                                    <Grid
                                                                        container
                                                                    >
                                                                        <Grid
                                                                            item
                                                                            xs={
                                                                                3
                                                                            }
                                                                        >
                                                                            <Grid
                                                                                container
                                                                            >
                                                                                <Grid
                                                                                    item
                                                                                    xs={
                                                                                        12
                                                                                    }
                                                                                >
                                                                                    <Chip
                                                                                        size="small"
                                                                                        label={
                                                                                            dtovalue?.type
                                                                                        }
                                                                                        sx={{
                                                                                            backgroundColor:
                                                                                                dtovalue?.type ===
                                                                                                    "Outside Kairos"
                                                                                                    ? "#E02020"
                                                                                                    : "#66C824",
                                                                                            color: "#ffffff",
                                                                                            borderRadius:
                                                                                                "5px",
                                                                                            height: 15
                                                                                        }}
                                                                                    />
                                                                                    {/* <Chip size="small" label={(dtovalue.bankName === "Others" || dtovalue.bankName === null) ? "Outside Kairos" : "Inside Kairos"} sx={{ backgroundColor: (dtovalue.bankName === "Others" || dtovalue.bankName === null) ? "#E02020" : "#66C824", color: "#ffffff", borderRadius: "5px", height: 15 }} /> */}
                                                                                    <Typography
                                                                                        variant="h5"
                                                                                        sx={{
                                                                                            fontWeight: 700
                                                                                        }}
                                                                                    >
                                                                                        {dtovalue.bankName ===
                                                                                            "Others" ||
                                                                                            dtovalue.bankName ===
                                                                                            null
                                                                                            ? dtovalue.assetName
                                                                                            : dtovalue.bankName}
                                                                                    </Typography>
                                                                                </Grid>
                                                                                <Grid
                                                                                    item
                                                                                    xs={
                                                                                        12
                                                                                    }
                                                                                >
                                                                                    {dtovalue.primaryCategoryId ===
                                                                                        null ? (
                                                                                        <Typography
                                                                                            variant="subtitle2"
                                                                                            sx={{
                                                                                                fontWeight: 600
                                                                                            }}
                                                                                        >
                                                                                            Ending
                                                                                            with{" "}
                                                                                            <Typography
                                                                                                variant="caption"
                                                                                                sx={{
                                                                                                    color: "#979797"
                                                                                                }}
                                                                                            >
                                                                                                {dtovalue?.bankAccountNumber?.substr(
                                                                                                    -4
                                                                                                )}
                                                                                            </Typography>
                                                                                        </Typography>
                                                                                    ) : (
                                                                                        <Typography variant="subtitle2">
                                                                                            {
                                                                                                dtovalue
                                                                                                    .primaryCategoryId
                                                                                                    ?.description
                                                                                            }
                                                                                        </Typography>
                                                                                    )}
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                        <Grid
                                                                            item
                                                                            xs={
                                                                                2
                                                                            }
                                                                            sx={{
                                                                                mt: 1
                                                                            }}
                                                                        >
                                                                            <Grid
                                                                                container
                                                                            >
                                                                                <Grid
                                                                                    item
                                                                                    xs={
                                                                                        12
                                                                                    }
                                                                                >
                                                                                    <Typography variant="subtitle2">
                                                                                        {dtovalue.bankName ===
                                                                                            "Other" ||
                                                                                            dtovalue.bankName ===
                                                                                            null
                                                                                            ? "Purchase Date"
                                                                                            : "Bank Code"}
                                                                                    </Typography>
                                                                                </Grid>
                                                                                <Grid
                                                                                    item
                                                                                    xs={
                                                                                        12
                                                                                    }
                                                                                >
                                                                                    <Typography
                                                                                        variant="h6"
                                                                                        sx={{
                                                                                            fontWeight: 600
                                                                                        }}
                                                                                    >
                                                                                        {dtovalue.bankName ===
                                                                                            "Other" ||
                                                                                            dtovalue.bankName ===
                                                                                            null
                                                                                            ? moment(
                                                                                                dtovalue.dateOfRequest
                                                                                            ).format(
                                                                                                "DD/MM/YYYY"
                                                                                            )
                                                                                            : dtovalue?.bankCode}
                                                                                    </Typography>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                        <Grid
                                                                            item
                                                                            xs={
                                                                                2
                                                                            }
                                                                            sx={{
                                                                                mt: 1
                                                                            }}
                                                                        >
                                                                            <Grid
                                                                                container
                                                                            >
                                                                                <Grid
                                                                                    item
                                                                                    xs={
                                                                                        12
                                                                                    }
                                                                                >
                                                                                    <Typography variant="subtitle2">
                                                                                        Synchup
                                                                                        Type
                                                                                    </Typography>
                                                                                </Grid>
                                                                                <Grid
                                                                                    item
                                                                                    xs={
                                                                                        12
                                                                                    }
                                                                                >
                                                                                    <Typography
                                                                                        variant="h6"
                                                                                        sx={{
                                                                                            fontSize:
                                                                                                "13px",
                                                                                            color:
                                                                                                dtovalue.synchupType ===
                                                                                                    "Offline"
                                                                                                    ? "#E02020"
                                                                                                    : "#1DB954"
                                                                                        }}
                                                                                    >
                                                                                        {
                                                                                            dtovalue.synchupType
                                                                                        }
                                                                                    </Typography>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                        <Grid
                                                                            item
                                                                            xs={
                                                                                1
                                                                            }
                                                                            sx={{
                                                                                mt: 1
                                                                            }}
                                                                        >
                                                                            <Grid
                                                                                container
                                                                            >
                                                                                <Grid
                                                                                    item
                                                                                    xs={
                                                                                        12
                                                                                    }
                                                                                >
                                                                                    <Typography variant="subtitle2">
                                                                                        Status
                                                                                    </Typography>
                                                                                </Grid>
                                                                                <Grid
                                                                                    item
                                                                                    xs={
                                                                                        12
                                                                                    }
                                                                                >
                                                                                    <Typography
                                                                                        variant="h6"
                                                                                        sx={{
                                                                                            fontSize:
                                                                                                "13px",
                                                                                            color:
                                                                                                dtovalue?.status ===
                                                                                                    "Pending"
                                                                                                    ? "#E02020"
                                                                                                    : "#1DB954"
                                                                                        }}
                                                                                    >
                                                                                        {
                                                                                            dtovalue?.status
                                                                                        }
                                                                                    </Typography>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                        {dtovalue?.status !==
                                                                            "pending" && (
                                                                                <Grid
                                                                                    item
                                                                                    xs={
                                                                                        2
                                                                                    }
                                                                                >
                                                                                    <Grid
                                                                                        container
                                                                                    >
                                                                                        {(dtovalue.bankName ===
                                                                                            "Others" ||
                                                                                            dtovalue.bankName ===
                                                                                            null) && (
                                                                                                <Grid
                                                                                                    item
                                                                                                    xs={
                                                                                                        12
                                                                                                    }
                                                                                                >
                                                                                                    <Typography variant="subtitle2">
                                                                                                        Current
                                                                                                        Value
                                                                                                        ($)
                                                                                                    </Typography>
                                                                                                </Grid>
                                                                                            )}
                                                                                        <Grid
                                                                                            item
                                                                                            xs={
                                                                                                12
                                                                                            }
                                                                                        >
                                                                                            <Typography
                                                                                                variant="h5"
                                                                                                sx={{
                                                                                                    textOverflow:
                                                                                                        "ellipsis",
                                                                                                    whiteSpace:
                                                                                                        "nowrap",
                                                                                                    overflow:
                                                                                                        "hidden"
                                                                                                }}
                                                                                            >{`$ ${dtovalue.balance}`}</Typography>
                                                                                        </Grid>
                                                                                        <Grid
                                                                                            item
                                                                                            xs={
                                                                                                12
                                                                                            }
                                                                                        >
                                                                                            <Typography
                                                                                                variant="subtitle2"
                                                                                                sx={{
                                                                                                    color: "#8A8993"
                                                                                                }}
                                                                                            >
                                                                                                Last
                                                                                                updated:
                                                                                                Just
                                                                                                now
                                                                                            </Typography>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            )}
                                                                        {dtovalue?.status !==
                                                                            "pending" && (
                                                                                <Grid
                                                                                    item
                                                                                    xs={
                                                                                        2
                                                                                    }
                                                                                >
                                                                                    {!readOnly && (
                                                                                        <Grid
                                                                                            container
                                                                                        >
                                                                                            <Grid
                                                                                                item
                                                                                                xs={
                                                                                                    12
                                                                                                }
                                                                                                sx={{
                                                                                                    p: 0.25
                                                                                                }}
                                                                                            >
                                                                                                {dtovalue.synchupType ===
                                                                                                    "Offline" ? (
                                                                                                    <Button
                                                                                                        style={{
                                                                                                            color: "white",
                                                                                                            width: "130px",
                                                                                                            height: "25px",
                                                                                                            background:
                                                                                                                "#6A3BE2 0% 0% no-repeat padding-box",
                                                                                                            boxShadow:
                                                                                                                "1px 8px 29px #695F9724",
                                                                                                            borderRadius:
                                                                                                                "8px",
                                                                                                            opacity: 1
                                                                                                        }}
                                                                                                        onClick={() =>
                                                                                                            handleOpen(
                                                                                                                dtovalue.id
                                                                                                            )
                                                                                                        }
                                                                                                    >
                                                                                                        {
                                                                                                            "Re-evaluate"
                                                                                                        }
                                                                                                    </Button>
                                                                                                ) : (
                                                                                                    <Button
                                                                                                        style={{
                                                                                                            color: "white",
                                                                                                            width: "130px",
                                                                                                            height: "25px",
                                                                                                            background:
                                                                                                                "#6A3BE2 0% 0% no-repeat padding-box",
                                                                                                            boxShadow:
                                                                                                                "1px 8px 29px #695F9724",
                                                                                                            borderRadius:
                                                                                                                "8px",
                                                                                                            opacity: 1
                                                                                                        }}
                                                                                                        onClick={() =>
                                                                                                            handleRefresh(
                                                                                                                dtovalue.id
                                                                                                            )
                                                                                                        }
                                                                                                    >
                                                                                                        {
                                                                                                            "Refresh"
                                                                                                        }
                                                                                                    </Button>
                                                                                                )}
                                                                                            </Grid>
                                                                                            <Grid
                                                                                                item
                                                                                                xs={
                                                                                                    12
                                                                                                }
                                                                                                sx={{
                                                                                                    p: 0.25
                                                                                                }}
                                                                                            >
                                                                                                <Button
                                                                                                    style={{
                                                                                                        color: "#FFFFFF",
                                                                                                        width: "130px",
                                                                                                        height: "25px",
                                                                                                        background:
                                                                                                            "#000000 0% 0% no-repeat padding-box",
                                                                                                        boxShadow:
                                                                                                            "1px 8px 29px #695F9724",
                                                                                                        border: "8px",
                                                                                                        borderRadius:
                                                                                                            "8px",
                                                                                                        opacity: 1
                                                                                                    }}
                                                                                                    onClick={() =>
                                                                                                        handleDelinkBankDetails(
                                                                                                            dtovalue.id,
                                                                                                            dtovalue.bankName,
                                                                                                            dtovalue.bankAccountNumber
                                                                                                        )
                                                                                                    }
                                                                                                >
                                                                                                    De-Link
                                                                                                </Button>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    )}
                                                                                </Grid>
                                                                            )}
                                                                    </Grid>
                                                                </Container>
                                                            )
                                                        }
                                                    )}
                                            {assetdetails?.find(
                                                (val) =>
                                                    val.category ===
                                                    catitem.description
                                            )?.bankDetailsDtoList === null && (
                                                    <Container
                                                        sx={{
                                                            background:
                                                                "#F0EDED 0% 0% no-repeat padding-box",
                                                            borderRadius: "8px",
                                                            opacity: 1,
                                                            p: 2,
                                                            mb: 2
                                                        }}
                                                    >
                                                        <Typography>
                                                            No records found
                                                        </Typography>
                                                    </Container>
                                                )}
                                        </Grid>
                                    </Grid>
                                </TabPanel>
                            )
                        })}
                    </TabContext>
                )}
            </Box>
        </>
    )
}

export default Institutions
