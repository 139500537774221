import { InputBase } from "@mui/material"
import { styled, alpha } from "@mui/material/styles"

export const Search = styled("div")(({ theme }) => ({
    position: "relative",
    "&:hover": {
        backgroundColor: alpha(theme.palette.common.white, 0.25)
    },
    marginLeft: 0,
    // width: '70%',
    height: "34px",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #DEDEDE",
    borderRadius: "8px",
    [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(1),
        width: "auto"
    }
}))
export const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
}))
export const StyledInputBase = styled(InputBase)(({ theme }) => ({
    font: "normal normal normal 14px/15px",
    fontFamily: " Futura",
    letterSpacing: "0px",
    borderRadius: "8px",
    // border:'1px solid red',
    color: "#939393 !important",
    "& .MuiInputBase-input": {
        padding: theme.spacing(0.7, 1, 2, 1),
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create("width"),
        // width: 'auto',
        // width: '250px',

        // border:'1px solid red',
        // borderRadius: '8px',
        font: "normal normal normal 14px/15px",
        fontFamily: "Avinar",
        color: "#000000 !important",
        [theme.breakpoints.up("sm")]: {
            // width: '205px',
            // '&:focus': {
            //     width: '20ch',
            // },
        }
    }
}))

export const StyledInputBaseDashboard = styled(InputBase)(({ theme }) => ({
    font: "normal normal normal 14px/15px",
    fontFamily: " Futura",
    letterSpacing: "0px",
    borderRadius: "8px",
    color: "#939393 !important",
    "& .MuiInputBase-input": {
        // padding: theme.spacing(1.3, 1, 2, 1),
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create("width"),

        font: "normal normal normal 14px/15px",
        fontFamily: " Futura",
        color: "#000000 !important",
        [theme.breakpoints.up("sm")]: {
            // width: '205px',
            // '&:focus': {
            //     width: '20ch',
            // },
        }
    }
}))
