import { DialogWrapper } from "components/common/Ui/Dialog"
import FormField from "../../components/FormField"
import { Button } from "components/common/Ui/Form"
import { Formik, Form } from "formik"
import FileInputWrapper from "../../components/FileInputWrapper"
import { Box } from "@mui/material"
import { IDENTITY_TYPE } from "../../constants"

const ProofOfIdentityModal = ({
    name,
    title,
    showModal,
    closeModal,
    initialValues,
    schema,
    handleSubmit,
    isLoading
}) => {
    const schemaList = [
        {
            name: "proofOfIdentitySelect",
            label: "Type of Identity",
            options: IDENTITY_TYPE,
            type: "select"
        },
        {
            name: "expiryDate",
            label: "Exprity date",
            type: "date",
            required: true
        }
    ]

    return (
        <DialogWrapper
            maxWidth="md"
            title={title}
            showModal={showModal}
            closeModal={closeModal}
        >
            <Formik
                initialValues={initialValues}
                validationSchema={schema}
                onSubmit={handleSubmit}
            >
                <Form sx={{ pb: 3 }}>
                    {schemaList.map((schema) => (
                        <Box mb={4} key={schema.name}>
                            <FormField
                                size="normal"
                                name={schema.name}
                                label={schema.label}
                                type={schema.type}
                                options={schema.options}
                                required={schema.required}
                            />
                        </Box>
                    ))}
                    <FileInputWrapper name={name} />
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            mt: 4
                        }}
                    >
                        <Button
                            disabled={isLoading}
                            type="submit"
                            variant="contained"
                        >
                            {isLoading ? "Saving..." : "Submit"}
                        </Button>
                    </Box>
                </Form>
            </Formik>
        </DialogWrapper>
    )
}

export default ProofOfIdentityModal
