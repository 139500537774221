import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    dropdownValues: {}, // Common dropdown values for all Category types
    users: {
        // lifestyleProducts: {},
        // financialProducts: {},
        // financialServices: {},
        // lifestyleServices: {}
    }
}

const productServiceSlice = createSlice({
    name: "productService",
    initialState,
    reducers: {
        // Dropdown values common object for four categpry
        updateDropdownValues: (state, action) => {
            state.dropdownValues = {
                ...state.dropdownValues,
                ...action.payload
            }
        },
        updateFinancialProducts: (state, action) => {
            const { user, listOfItems } = action.payload
            // Ensure the user object and financialProducts are initialized
            if (!state.users[user]) {
                state.users[user] = {}
            }
            if (!state.users[user].financialProducts) {
                state.users[user].financialProducts = {}
            }

            state.users[user].financialProducts.dataFull = {
                ...state.users[user]?.financialProducts?.dataFull, // preserve existing properties
                ...listOfItems?.dataFull // add/overwrite with new properties
            }
            state.users[user].financialProducts.assetClassEnabledList = {
                ...state.users[user]?.financialProducts?.assetClassEnabledList, // preserve existing properties
                ...listOfItems?.assetClassEnabledList // add/overwrite with new properties
            }
            state.users[user].financialProducts.assetClassesList = {
                ...state.users[user]?.financialProducts?.assetClassesList, // preserve existing properties
                ...listOfItems?.assetClassesList // add/overwrite with new properties
            }
        },
        updateLifestyleProducts: (state, action) => {
            const { user, listOfItems } = action.payload
            // Ensure the user object and lifestyleProducts are initialized
            if (!state.users[user]) {
                state.users[user] = {}
            }
            if (!state.users[user].lifestyleProducts) {
                state.users[user].lifestyleProducts = {}
            }

            state.users[user].lifestyleProducts.dataFull = {
                ...state.users[user]?.lifestyleProducts?.dataFull, // preserve existing properties
                ...listOfItems?.dataFull // add/overwrite with new properties
            }
            state.users[user].lifestyleProducts.assetClassEnabledList = {
                ...state.users[user]?.lifestyleProducts?.assetClassEnabledList, // preserve existing properties
                ...listOfItems?.assetClassEnabledList // add/overwrite with new properties
            }
            state.users[user].lifestyleProducts.assetClassesList = {
                ...state.users[user]?.lifestyleProducts?.assetClassesList, // preserve existing properties
                ...listOfItems?.assetClassesList // add/overwrite with new properties
            }
        },
        updateFinancialServices: (state, action) => {
            const { user, listOfItems } = action.payload
            // Ensure the user object and financialServices are initialized
            if (!state.users[user]) {
                state.users[user] = {}
            }
            if (!state.users[user].financialServices) {
                state.users[user].financialServices = {}
            }

            state.users[user].financialServices.dataFull = {
                ...state.users[user]?.financialServices?.dataFull, // preserve existing properties
                ...listOfItems?.dataFull // add/overwrite with new properties
            }
            state.users[user].financialServices.assetClassEnabledList = {
                ...state.users[user]?.financialServices?.assetClassEnabledList, // preserve existing properties
                ...listOfItems?.assetClassEnabledList // add/overwrite with new properties
            }
            state.users[user].financialServices.assetClassesList = {
                ...state.users[user]?.financialServices?.assetClassesList, // preserve existing properties
                ...listOfItems?.assetClassesList // add/overwrite with new properties
            }
        },
        updateLifestyleServices: (state, action) => {
            const { user, listOfItems } = action.payload
            // Ensure the user object and lifestyleServices are initialized
            if (!state.users[user]) {
                state.users[user] = {}
            }
            if (!state.users[user].lifestyleServices) {
                state.users[user].lifestyleServices = {}
            }

            state.users[user].lifestyleServices.dataFull = {
                ...state.users[user]?.lifestyleServices?.dataFull, // preserve existing properties
                ...listOfItems?.dataFull // add/overwrite with new properties
            }
            state.users[user].lifestyleServices.assetClassEnabledList = {
                ...state.users[user]?.lifestyleServices?.assetClassEnabledList, // preserve existing properties
                ...listOfItems?.assetClassEnabledList // add/overwrite with new properties
            }
            state.users[user].lifestyleServices.assetClassesList = {
                ...state.users[user]?.lifestyleServices?.assetClassesList, // preserve existing properties
                ...listOfItems?.assetClassesList // add/overwrite with new properties
            }
        }
    }
})

export const {
    updateDropdownValues,
    updateFinancialProducts,
    updateLifestyleProducts,
    updateFinancialServices,
    updateLifestyleServices
} = productServiceSlice.actions

export default productServiceSlice.reducer
