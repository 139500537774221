import { createSlice } from "@reduxjs/toolkit"

const hniProfileSlice = createSlice({
    name: "product",
    initialState: {
        hniDetails: {
            clientPersonal: {
                email: "",
                secondaryEmail: "",
                salutation: {
                    description: ""
                },
                firstName: "",
                lastName: "",
                mobile: "",
                altMobile: "",
                dob: "",
                gender: {
                    description: ""
                },
                countryId: {
                    countryName: ""
                },
                cityId: {
                    name: ""
                },
                stateId: {
                    stateName: ""
                },
                postalCode: "",
                address1: "",
                address2: "",
                address3: "",
                baseLocationId: {
                    countryName: ""
                },
                originId: {
                    description: ""
                },
                highestLevelOfEducation: {
                    description: ""
                }
            },
            clientFinancial: {
                presentAssetAllocation: [],
                takeSurvey: false,
                netWorth: 0,
                sourceOfWealth: [],
                investibleWealth: "",
                riskAppetite: {
                    description: ""
                },
                industryPreference: [],
                financialGoals: [],
                joiningFee: 0,
                subscriptionFee: 0
            },
            clientProfessional: {
                professionType: [],
                industry: [],
                domainSector: [],
                turnover: ""
            },
            clientFamily: {
                isItAHereditaryBuisness: {
                    description: ""
                },
                maritalStatus: {
                    description: ""
                },
                noOfFamilyMembers: 0,
                memberDetails: []
            },
            clientInterest: {
                financialProduct: [],
                lifestyleProduct: [],
                financialService: [],
                lifestyleService: [],
                personality: [],
                currentMemberships: [],
                previousVacations: []
            },
            clientKyc: {
                proofOfIdentity: {
                    proofOfIdentitySelect: {
                        description: ""
                    },
                    idNumber: "",
                    expiryDate: "",
                    name: "",
                    url: ""
                },
                proofOfAddress: {
                    proofOfAddressSelect: {
                        description: ""
                    },
                    address1: "",
                    address2: "",
                    city: "",
                    state: "",
                    zipcode: "",
                    name: "",
                    url: ""
                },
                proofOfTaxResidency: {
                    name: "",
                    url: ""
                },
                proofOfWealth: [],
                sourceOfWealth: []
            }
        }
    },
    reducers: {
        setHniDetails: (state, action) => {
            state.hniDetails = action.payload
        }
    }
})
export const { setHniDetails } = hniProfileSlice.actions

export default hniProfileSlice.reducer
