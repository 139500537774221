import React, { useState, useEffect } from "react"

const Timer = ({ handlelogOut }) => {
    const [time, setTime] = useState({ minutes: 0, seconds: 29 })

    useEffect(() => {
        const lastActiveTime = localStorage.getItem("sectimer")

        if (lastActiveTime) {
            const currentTime = new Date().getTime()
            const elapsedTime = Math.floor(
                (currentTime - parseInt(lastActiveTime)) / 1000
            )
            const remainingSeconds = Math.max(0, 30 - elapsedTime)
            const remainingMinutes = Math.floor(remainingSeconds / 60)
            setTime({
                minutes: remainingMinutes,
                seconds: remainingSeconds % 60
            })
        }

        const interval = setInterval(() => {
            setTime((prevTime) => {
                if (prevTime.seconds > 0 || prevTime.minutes > 0) {
                    const newSeconds =
                        prevTime.seconds === 0 ? 29 : prevTime.seconds - 1
                    const newMinutes =
                        prevTime.seconds === 0
                            ? prevTime.minutes - 1
                            : prevTime.minutes
                    const remainingTime = newMinutes * 60 + newSeconds
                    localStorage.setItem(
                        "sectimer",
                        new Date().getTime() + remainingTime * 1000
                    )
                    return { minutes: newMinutes, seconds: newSeconds }
                } else {
                    clearInterval(interval)
                    handlelogOut()
                    return prevTime // No need to update time if it's already at 0:00
                }
            })
        }, 1000)

        return () => clearInterval(interval)
    }, [handlelogOut])

    return (
        <>{`${time.minutes}:${time.seconds < 10 ? `0${time.seconds}` : time.seconds}`}</>
    )
}

export default Timer
