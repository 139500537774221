import * as React from "react"
import { Dialog, DialogContent, DialogTitle } from "@mui/material"

export default function DialogWrapper({
    maxWidth = "lg",
    showModal,
    closeModal,
    children,
    title
}) {
    return (
        <Dialog
            fullWidth={true}
            maxWidth={maxWidth}
            open={showModal}
            onClose={closeModal}
            sx={{
                "& .MuiDialogTitle-root": {
                    px: 4,
                    py: 3
                },
                "& .MuiDialogContent-root": {
                    padding: 4
                }
            }}
        >
            <DialogTitle sx={{ fontWeight: 500, fontSize: "24px" }}>
                {title}
            </DialogTitle>
            <DialogContent dividers>{children}</DialogContent>
        </Dialog>
    )
}
