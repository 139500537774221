import { Box, Typography } from "@mui/material"

const KycSectionWrapper = ({ title, children, headerSlot }) => {
    return (
        <Box
            sx={{
                px: 4,
                py: 2,
                mb: 2,
                borderRadius: "12px",
                border: "1px solid",
                borderColor: "gray.300"
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: 3
                }}
            >
                <Typography
                    variant="h4"
                    sx={{ fontWeight: 500, color: "gray.700" }}
                >
                    {title}
                </Typography>
                {headerSlot}
            </Box>
            {children}
        </Box>
    )
}
export default KycSectionWrapper
