
import { Box, Typography } from "@mui/material"

const RadioGroupSection = ({ title, children }) => {
    return (
        <Box
            sx={{
                px: 4,
                py: 2,
                mb: 2,
                borderRadius: "12px",
                border: "1px solid",
                borderColor: "gray.300"
            }}
        >
            <Typography
                variant="h4"
                sx={{ fontWeight: 500, mb: 3, color: "gray.700" }}
            >
                {title}
            </Typography>
            {children}
        </Box>
    )
}
export default RadioGroupSection
