import { useDispatch, useSelector } from "react-redux"
import { goBack } from "redux/slices/selfOnboardSlice"
import { Box, Tooltip, Button } from "@mui/material"
import { NavigateBefore, NavigateNext } from "@mui/icons-material"
import { Button as BtnWrapper } from "components/common/Ui/Form"

const FooterAction = ({ isLoading, docSaved, handleNext }) => {
    const dispatch = useDispatch()
    const { activeStep } = useSelector((state) => state.selfOnboard)

    const handleBackBtn = () => {
        dispatch(goBack())
    }

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "row",
                pt: 2,
                justifyContent: "space-between",
                alignItems: "center"
            }}
        >
            <BtnWrapper
                variant="outlined"
                color="brand"
                sx={{
                    mr: 1
                }}
                disabled={activeStep === 0}
                onClick={handleBackBtn}
                startIcon={NavigateBefore}
            >
                Back
            </BtnWrapper>
            <Tooltip
                title={
                    docSaved
                        ? null
                        : "To proceed further, you must upload all necessary documents first"
                }
            >
                <span>
                    <Button
                        color="brand"
                        sx={{
                            borderRadius: "999px",
                            padding: "10px 30px"
                        }}
                        variant="contained"
                        endIcon={<NavigateNext />}
                        type="submit"
                        disabled={isLoading || !docSaved}
                        onClick={handleNext}
                    >
                        {isLoading ? "Saving..." : "Next"}
                    </Button>
                </span>
            </Tooltip>
        </Box>
    )
}
export default FooterAction
