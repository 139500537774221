import * as Yup from "yup"
import { AddOutlined } from "@mui/icons-material"
import { Button } from "components/common/Ui/Form"
import FormWrapper from "../../components/FormWrapper"
import { checkPdfFormat, checkPdfSize } from "../../utils"
import { useDispatch, useSelector } from "react-redux"
import {
    goToNextIndividualKyc,
    saveIndivProofOfAddress,
    saveIndivProofOfIdentity
} from "redux/slices/selfOnboardSlice"
import FormAction from "../KycFooterAction"
import { ConfirmDeleteDialog } from "components/common/Ui/Dialog"
import { useState } from "react"
import { toast } from "react-toastify"
import { useSelfOnboardApi } from "hooks/useSelfOnboardApi"
import {
    deleteSelfOnboardKyc,
    saveSelfOnboardKyc
} from "services/prospectsServices"
import KycDocumentModal from "../KycDocumentModal"
import KycSectionWrapper from "../KycSectionWrapper"
import KycDocumentList from "../KycDocumentList"
import { Tooltip } from "@mui/material"
import ProofOfIdentityModal from "./ProofOfIdentityModal"

// ** Constants
const title = "KYC documents"
const subtitle =
    "To comply with regulatory requirements, we need you to upload Know Your Customer (KYC) documents. These documents will help verify your identity."
const SUCCESS_MSG = "Success! Your document is now safe and saved"
const FAILED_MSG = "Something went wrong! We couldn't save your document."
const SUCCESS_DEL_MSG = "Success! Your document is deleted"
const FAILED_DEL_MSG = "Something went wrong! We couldn't delete your document."

const fileSchema = Yup.mixed()
    .required("Document required")
    .test(
        "fileformat",
        "Supported document formats: pdf,jpg,jpeg,png",
        checkPdfFormat
    )
    .test("filesize", "Document size must not exceed 9 MB", checkPdfSize)

const MIN_MONTH = 6

const getMinExpiryDate = () => {
    const currentDate = new Date()
    currentDate.setMonth(currentDate.getMonth() + MIN_MONTH)
    return currentDate
}

// ** Main comp
export default function IndividualKyc() {
    const dispatch = useDispatch()
    const { post, isLoading } = useSelfOnboardApi()
    const { post: deleteDoc, isLoading: isDeleting } = useSelfOnboardApi()
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [showIdentityModal, setShowIdentityModal] = useState(false)
    const [showAddressModal, setShowAddressModal] = useState(false)
    const [deletePayload, setDeletePayload] = useState(null)
    const {
        prospect_id,
        individualKycDetails: { proofOfAddress, proofOfIdentity }
    } = useSelector((state) => state.selfOnboard)

    const proofOfAddressSaved = proofOfAddress !== null
    const proofOfIdentitySaved = proofOfIdentity !== null

    const handleSave = async (data) => {
        const field = Object.keys(data).at(0)
        const payload = new FormData()
        payload.append(field, data[field])

        const meta = { prospectId: prospect_id, field }

        if (field === "proofOfIdentity") {
            meta["expiryDate"] = data.expiryDate
            meta["proofOfIdentitySelect"] = data.proofOfIdentitySelect || ""
        }

        payload.append("prospectKycRequest", JSON.stringify(meta, null, 2))

        const resp = await post({ apiCaller: saveSelfOnboardKyc, payload })

        if (resp.data?.status) {
            toast.success(SUCCESS_MSG)
            const { proofOfAddress, proofOfIdentity } = resp.data.body

            // ** Dispatch and close the modal
            if (field === "proofOfIdentity") {
                dispatch(saveIndivProofOfIdentity(proofOfIdentity))
                setShowIdentityModal(false)
            } else {
                dispatch(saveIndivProofOfAddress(proofOfAddress))
                setShowAddressModal(false)
            }
        } else {
            toast.error(resp.data.errorMessage || FAILED_MSG)
        }
    }

    const deleteDocument = async () => {
        const payload = {
            prospectId: prospect_id,
            ...deletePayload
        }

        const resp = await deleteDoc({
            apiCaller: deleteSelfOnboardKyc,
            payload
        })

        if (resp?.data?.status) {
            toast.success(SUCCESS_DEL_MSG)
            dispatch(
                deletePayload.field === "proofOfIdentity"
                    ? saveIndivProofOfIdentity(null)
                    : saveIndivProofOfAddress(null)
            )
            setShowDeleteModal(false)
        } else {
            toast.error(resp?.data?.errorMessage || FAILED_DEL_MSG)
        }
    }

    const handleDeleteClick = (payload) => {
        setDeletePayload(payload)
        setShowDeleteModal(true)
    }

    const handleNext = () => {
        dispatch(goToNextIndividualKyc())
    }

    return (
        <>
            <FormWrapper title={title} subtitle={subtitle}>
                <KycSectionWrapper
                    title="Proof Of Identity"
                    headerSlot={
                        <Tooltip
                            title={
                                proofOfIdentitySaved
                                    ? "Proof of identity document already uploaded. To upload new one, you must delete existing one"
                                    : null
                            }
                        >
                            <span>
                                <Button
                                    disabled={proofOfIdentitySaved}
                                    onClick={() => setShowIdentityModal(true)}
                                    variant="outlined"
                                    startIcon={AddOutlined}
                                >
                                    Document
                                </Button>
                            </span>
                        </Tooltip>
                    }
                >
                    <KycDocumentList
                        data={
                            proofOfIdentitySaved
                                ? [
                                      {
                                          ...proofOfIdentity,
                                          field: "proofOfIdentity"
                                      }
                                  ]
                                : []
                        }
                        handleDeleteClick={handleDeleteClick}
                    />
                </KycSectionWrapper>
                <KycSectionWrapper
                    title="Proof Of Address"
                    headerSlot={
                        <Tooltip
                            title={
                                proofOfAddressSaved
                                    ? "Proof of address document already uploaded. To upload new one, you must delete existing one"
                                    : null
                            }
                        >
                            <span>
                                <Button
                                    disabled={proofOfAddressSaved}
                                    onClick={() => setShowAddressModal(true)}
                                    variant="outlined"
                                    startIcon={AddOutlined}
                                >
                                    Document
                                </Button>
                            </span>
                        </Tooltip>
                    }
                >
                    <KycDocumentList
                        data={
                            proofOfAddressSaved
                                ? [
                                      {
                                          ...proofOfAddress,
                                          field: "proofOfAddress"
                                      }
                                  ]
                                : []
                        }
                        handleDeleteClick={handleDeleteClick}
                    />
                </KycSectionWrapper>
                <FormAction
                    handleNext={handleNext}
                    docSaved={proofOfAddressSaved && proofOfIdentitySaved}
                />
            </FormWrapper>
            <ConfirmDeleteDialog
                isLoading={isDeleting}
                handleCancelBtn={() => setShowDeleteModal(false)}
                showModal={showDeleteModal}
                handleDeleteBtn={deleteDocument}
            />
            <ProofOfIdentityModal
                schema={Yup.object({
                    proofOfIdentity: fileSchema,
                    proofOfIdentitySelect: Yup.string(),
                    expiryDate: Yup.date()
                        .min(
                            getMinExpiryDate(),
                            `Identity must have a minimum validity of ${MIN_MONTH} months`
                        )
                        .typeError("Valid date required")
                        .required("Expiry date required")
                })}
                isLoading={isLoading}
                initialValues={{
                    proofOfIdentity: null
                }}
                name="proofOfIdentity"
                showModal={showIdentityModal}
                closeModal={() => setShowIdentityModal(false)}
                title="Proof of Identity"
                handleSubmit={handleSave}
            />
            <KycDocumentModal
                isLoading={isLoading}
                schema={Yup.object({ proofOfAddress: fileSchema })}
                initialValues={{
                    proofOfAddress: null
                }}
                name="proofOfAddress"
                showModal={showAddressModal}
                closeModal={() => setShowAddressModal(false)}
                title="Proof of Address"
                handleSubmit={handleSave}
            />
        </>
    )
}
