import { Formik, Form, Field } from "formik"

import * as Yup from "yup"
import FormWrapper from "../../components/FormWrapper"
import RadioGroupWrapper from "./RadioGroupWrapper"
import SectionWrapper from "./SectionWrapper"
import { SelectWrapper } from "components/common/Ui/Form"
import { useDispatch, useSelector } from "react-redux"
import FormAction from "../../components/FormAction"
import { goToNextEntitySuitability } from "redux/slices/selfOnboardSlice"
import { useSelfOnboardApi } from "hooks/useSelfOnboardApi"
import { updateSelfOnboardProspect } from "services/prospectsServices"
import { toast } from "react-toastify"

const stateOwned = [
    {
        value: "Y",
        label: "Yes",
        subtext: "My entity is a State Owned Entity."
    },
    {
        value: "N",
        label: "No",
        subtext: "My entity is not a State Owned Entity."
    }
]

const privateMarketsAllowed = [
    {
        value: "Y",
        label: "Yes",
        subtext: "My company is allowed to invest in private markets."
    },
    {
        value: "N",
        label: "No",
        subtext: "My company is not allowed to invest in private markets."
    }
]

const profOrAccredInvestor = [
    {
        value: "Y",
        label: "Yes",
        subtext: "My investors are professional or accredited investors."
    },
    {
        value: "N",
        label: "No",
        subtext: "My investors are not professional or accredited investors."
    }
]
const title = "Suitability details"
const subtitle =
    "Please provide additional information about your entity's investment eligibility and status."

const validationSchema = Yup.object({
    is_state_owned: Yup.string()
        .oneOf(["N"], "Entity should not be state owned")
        .required("Entity should not be state owned"),
    is_private_allowed: Yup.string()
        .oneOf(["Y"], "Entity must be allowed to invest in private markets")
        .required("Entity must be allowed to invest in private markets"),

    is_prof: Yup.string()
        .oneOf(["Y"], "Your investors must be professional or accredited")
        .required("Your investors must be professional or accredited"),
    risk_appetite: Yup.string().required("Risk appetite required"),
    financial_goals: Yup.array()
        .of(Yup.string())
        .required("Financial goals required"),
    industry_preference: Yup.array()
        .of(Yup.string())
        .required("Industry preferences required")
})

export default function EntitySuitability() {
    const { post, isLoading } = useSelfOnboardApi()
    const {
        prospect_id,
        email,
        token,
        entitySuitabilityDetails,
        dropDowns: { industryPreferences, financialGoals, riskAppetites }
    } = useSelector((state) => state.selfOnboard)
    const dispatch = useDispatch()

    const handleNext = async (data) => {
        const { financial_goals, risk_appetite, industry_preference } = data
        const payload = {
            financial_goals,
            risk_appetite,
            industry_preference,
            prospect_id,
            email,
            token
        }

        const resp = await post({
            apiCaller: updateSelfOnboardProspect,
            payload
        })

        if (resp.status === 500) {
            toast.error("Something went wrong. Try contacting support teams")
            return
        }
        dispatch(goToNextEntitySuitability(data))
    }

    const schemaList = [
        {
            title: "Risk appetite",
            name: "risk_appetite",
            label: "Risk appetite",
            required: true,
            options: riskAppetites || []
        },
        {
            title: "Financial goals",
            name: "financial_goals",
            label: "Financial goals",
            required: true,
            multiple: true,
            options: financialGoals || []
        },
        {
            name: "industry_preference",
            label: "Industry preferences",
            title: "Industry preferences",
            required: true,
            options: industryPreferences || [],
            multiple: true
        }
    ]

    // Field Schema
    return (
        <FormWrapper title={title} subtitle={subtitle}>
            <Formik
                initialValues={entitySuitabilityDetails}
                validationSchema={validationSchema}
                onSubmit={handleNext}
            >
                <Form>
                    <SectionWrapper title="Is your entity a State Owned Entity?">
                        <Field
                            name="is_state_owned"
                            component={RadioGroupWrapper}
                            options={stateOwned}
                        />
                    </SectionWrapper>
                    <SectionWrapper title="Is your company allowed to invest in private markets?">
                        <Field
                            name="is_private_allowed"
                            component={RadioGroupWrapper}
                            options={privateMarketsAllowed}
                        />
                    </SectionWrapper>

                    <SectionWrapper title="Are all your investors professional or accredited investors?">
                        <Field
                            name="is_prof"
                            component={RadioGroupWrapper}
                            options={profOrAccredInvestor}
                        />
                    </SectionWrapper>
                    {schemaList.map(
                        ({ name, label, multiple, options, title }) => (
                            <SectionWrapper key={name} title={title}>
                                <SelectWrapper
                                    name={name}
                                    options={options}
                                    required
                                    size="normal"
                                    label={label}
                                    multiple={multiple}
                                />
                            </SectionWrapper>
                        )
                    )}
                    <FormAction isLoading={isLoading} />
                </Form>
            </Formik>
        </FormWrapper>
    )
}
