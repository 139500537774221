/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */
import React, { useEffect } from "react"
import { useState } from "react"
import {
    Autocomplete,
    Typography,
    FormControl,
    InputLabel,
    Select,
    Grid,
    IconButton,
    TextField,
    Button,
    Box
} from "@mui/material"
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"
import AddIcon from "@mui/icons-material/Add"

import { useSelector, useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"

import {
    setSelectedFolder,
    setIsCreateFolderModalOpen,
    setIsUploadDocumentModalOpen,
    setIsDeleteFolderModalOpen,
    setIsDeleteDocumentModalOpen,
    setIsDeleteFolderDisabled,
    setSelectedFile,
    setFiles,
    setResponseList,
    setIsLoading
} from "../../../../redux/slices/ChatGptSlice"

import styles from "../sidebar.module.css"
import { formatDate } from "../../../common/Utils/DateFormat"

export default function SidebarInternalData({ queryAPI }) {
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const folders = useSelector((state) => state.chatGpt.folders)
    const selectedFolder = useSelector((state) => state.chatGpt.selectedFolder)
    const selectedFile = useSelector((state) => state.chatGpt.selectedFile)
    const isDeleteFolderDisabled = useSelector(
        (state) => state.chatGpt.isDeleteFolderDisabled
    )
    const files = useSelector((state) => state.chatGpt.files)
    const [isDeleteFileDisabled, setIsDeleteFileDisabled] = useState(true)

    const handleFolderChange = (event, value) => {
        if (value === null) {
            value = ""
        }
        dispatch(setSelectedFolder(value))
        // eslint-disable-next-line
        if (value != "") {
            queryAPI("index", "GET", value, "").then((response) => {
                dispatch(
                    setFiles(
                        response.documents.map((input_string) => {
                            return input_string
                        })
                    )
                )
                dispatch(setIsDeleteFolderDisabled(false))
            })
        } else {
            dispatch(setIsDeleteFolderDisabled(true))
        }
    }

    const handleChangeFileSelector = (event) => {
        dispatch(setSelectedFile(event.target.value))
    }

    useEffect(() => {
        if (selectedFile !== "") {
            setIsDeleteFileDisabled(false)
        } else {
            setIsDeleteFileDisabled(true)
        }
    }, [selectedFile])

    const handleSummarization = () => {
        const currentDate = new Date()
        dispatch(setIsLoading(true))
        queryAPI(
            "ask_summarize",
            "GET",
            "?filename=" + encodeURIComponent(selectedFile),
            ""
        ).then((response) => {
            let summaryAnswer = [
                {
                    date: formatDate(currentDate, "DD-MMM-YYYY"),
                    question: "Summary of " + selectedFile,
                    bookmark: "No",
                    answer: response,
                    chatGptId: 1, //res?.data?.body[0]?.chatGptId
                    ticker: ""
                }
            ]
            dispatch(setResponseList(summaryAnswer))
            dispatch(setIsLoading(false))
        })
    }

    return (
        <>
            <Grid
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                sx={{ px: 1, mb: 1 }}
            >
                <Typography>{t("Pick Internal Data folder")}</Typography>
                <Grid
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-end"
                >
                    <IconButton
                        aria-label="Delete Folder"
                        disabled={isDeleteFolderDisabled}
                        sx={{ padding: "0" }}
                        onClick={() => {
                            dispatch(setIsDeleteFolderModalOpen(true))
                        }}
                    >
                        <DeleteOutlineIcon p={0} />
                    </IconButton>
                    <AddIcon
                        p={0}
                        sx={{ cursor: "pointer" }}
                        onClick={() =>
                            dispatch(setIsCreateFolderModalOpen(true))
                        }
                    />
                </Grid>
            </Grid>
            <Autocomplete
                id="folder-selector"
                width="100%"
                size="small"
                sx={{ px: 1 }}
                options={folders}
                value={selectedFolder}
                isOptionEqualToValue={(option, value) => option === value}
                renderInput={(params) => (
                    <TextField {...params} label={t("Folder")} />
                )}
                onChange={handleFolderChange}
            />
            <Grid
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                px={1}
                mt={2}
                mb={1}
            >
                <Typography>{t("Documents in folder")}</Typography>
                <Grid
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-end"
                >
                    <IconButton
                        aria-label="Delete File"
                        disabled={isDeleteFileDisabled}
                        sx={{ cursor: "pointer", padding: "0" }}
                        onClick={() =>
                            dispatch(setIsDeleteDocumentModalOpen(true))
                        }
                    >
                        <DeleteOutlineIcon p={0} />
                    </IconButton>
                    <IconButton
                        aria-label="Upload File"
                        disabled={selectedFolder === ""}
                        sx={{ cursor: "pointer", padding: "0" }}
                        onClick={() =>
                            dispatch(setIsUploadDocumentModalOpen(true))
                        }
                    >
                        <AddIcon p={0} />
                    </IconButton>
                </Grid>
            </Grid>
            <FormControl sx={{ px: 1, mb: 3 }}>
                <InputLabel shrink htmlFor="select-file">
                    {t("File")}
                </InputLabel>
                <Select
                    className={styles}
                    multiple
                    native
                    value={[selectedFile]}
                    onChange={handleChangeFileSelector}
                    label="Native"
                    inputProps={{
                        id: "select-file"
                    }}
                >
                    {files?.map((file) => (
                        <option key={file} value={file}>
                            {file}
                        </option>
                    ))}
                </Select>
            </FormControl>
            {/** Summarization button that becomes active when a file is selected */}
            <Box px={1} pb={4}>
                <Button
                    fullWidth
                    color="brand"
                    variant="contained"
                    disabled={selectedFile === ""}
                    onClick={handleSummarization}
                >
                    {t("Summarize document")}
                </Button>
            </Box>
        </>
    )
}
