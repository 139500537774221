import { useField } from "formik"
import { TextField } from "@mui/material"

const DateWrapper = ({ readOnly = false, name, size = "small", ...props }) => {
    const [field, meta] = useField(name)

    const dateConfig = {
        ...field,
        ...props,
        size,
        fullWidth: true,
        InputLabelProps: { shrink: true },
        type: "date",
        InputProps: {
            readOnly
        }
    }

    if (meta && meta.error && meta.touched) {
        dateConfig.error = true
        dateConfig.helperText = meta.error
    }
    return (
        <TextField
            sx={{
                "& .MuiOutlinedInput-root": {
                    "&:hover fieldset": {
                        borderColor: "#7450da"
                    },
                    "&.Mui-focused fieldset": {
                        borderColor: "#7450da"
                    }
                }
            }}
            {...dateConfig}
        />
    )
}

export default DateWrapper
