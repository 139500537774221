import { DialogWrapper } from "components/common/Ui/Dialog"
import { Button } from "components/common/Ui/Form"
import { Formik, Form } from "formik"
import FileInputWrapper from "../components/FileInputWrapper"
import { Box } from "@mui/material"

const KycDocumentModal = ({
    name,
    title,
    showModal,
    closeModal,
    initialValues,
    schema,
    handleSubmit,
    isLoading
}) => {
    return (
        <DialogWrapper
            maxWidth="md"
            title={title}
            showModal={showModal}
            closeModal={closeModal}
        >
            <Formik
                initialValues={initialValues}
                validationSchema={schema}
                onSubmit={handleSubmit}
            >
                <Form sx={{ pb: 3 }}>
                    <FileInputWrapper name={name} />
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            mt: 4
                        }}
                    >
                        <Button disabled={isLoading} type="submit" variant="contained">
                            {isLoading ? "Saving..." : "Submit"}
                        </Button>
                    </Box>
                </Form>
            </Formik>
        </DialogWrapper>
    )
}

export default KycDocumentModal
