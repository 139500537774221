import React from "react"
import { Modal, Typography, Box, Button } from "@mui/material"
import Timer from "./TimerRun"

const TimeOutWarning = ({ handlelogOut, open, handleContinueSession }) => {
    const localValueMethod = async () => {
        setTimeout(async () => {
            await handleContinueSession()
        }, 900)
    }

    return (
        <div>
            <Modal open={open}>
                <Box
                    style={{
                        position: "absolute",
                        marginTop: "10px",
                        top: "50%",
                        backgroundColor: "#FFFFFF",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        minWidth: 300,
                        borderRadius: "8px",
                        padding: "20px"
                    }}
                >
                    <div>
                        <div>
                            <Typography
                                variant="h2"
                                sx={{
                                    color: "#000000",
                                    fontSize: "18px",
                                    marginBottom: "10px",
                                    fontWeight: "500"
                                }}
                            >
                                Your Session Is About To Expire
                            </Typography>
                            <Typography
                                variant="p"
                                sx={{
                                    color: "#707070",
                                    fontSize: "16px",
                                    marginBottom: "20px"
                                }}
                            >
                                You will be signed out automatically if you do
                                not resume your session within next{" "}
                                {<Timer handlelogOut={handlelogOut} />} Seconds.
                            </Typography>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                gap: "10px",
                                marginTop: "10px",
                                justifyContent: "space-between"
                            }}
                        >
                            <Button
                                onClick={() => handlelogOut()}
                                variant="contained"
                                sx={{
                                    width: "120px",
                                    height: "32px",
                                    borderRadius: "40px",
                                    backgroundColor: "##010001",
                                    color: "#FFFFFF",
                                    fontSize: { xs: "12px", sm: "14px" },
                                    opacity: 1
                                }}
                            >
                                Sign Out
                            </Button>
                            <Button
                                onClick={() => {
                                    localValueMethod()
                                }}
                                variant="contained"
                                sx={{
                                    width: "auto",
                                    height: "32px",
                                    letterSpacing: "0.35px",
                                    opacity: 1,
                                    borderRadius: "40px",
                                    backgroundColor: "#6A3BDE",
                                    color: "#FFFFFF",
                                    padding: "15px",
                                    fontSize: { xs: "12px", sm: "14px" },
                                    "&:hover": {
                                        backgroundColor: "#6A3BDE",
                                        color: "#FFFFFF",
                                        opacity: 1
                                    }
                                }}
                            >
                                Resume Session
                            </Button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    )
}
export default TimeOutWarning
