import React from "react"
import Login from "../components/modules/login/Login"
import CreatePassword from "../components/modules/login/CreatePassword"
import Verify from "../components/modules/login/verification/Form"
import GuestLogin from "../components/modules/guestUser/guestLogin"
import GuestLayout from "../components/modules/guestUser/guestLayout"

export const PublicRouteConfig = [
    {
        name: "login",
        path: "/login",
        element: <Login />
    },
    {
        name: "login redirect",
        path: "/",
        element: <Login />
    },
    {
        name: "newpassword",
        path: "/setpassword",
        element: <CreatePassword />
    },
    {
        name: "verification",
        path: "/verify",
        element: <Verify />
    },
    {
        name: "guestUser",
        path: "/guestUser",
        element: <GuestLogin />
    },
    // {
    //     name: "guestVerification",
    //     path: "/guestVerification",
    //     element: <VerificationForm />,
    // },
    {
        // name: "guestdashboard",
        // path: "/guestdashboard",
        // element: <GuestLayout />,
    }
]
export const PublicGuestRouteConfig = [
    // {
    //     name: "login",
    //     path: "/login",
    //     element: <Login />,
    // },
    // {
    //     name: "login redirect",
    //     path: "/",
    //     element: <Login />,
    // },
    // {
    //     name: "newpassword",
    //     path: "/setpassword",
    //     element: <CreatePassword />,
    // },
    // {
    //     name: "verification",
    //     path: "/verify",
    //     element: <Verify />,
    // },
    // {
    //     name: "guestUser",
    //     path: "/guestUser",
    //     element: <GuestLogin />,
    // },
    // {
    //     name: "guestVerification",
    //     path: "/guestVerification",
    //     element: <VerificationForm />,
    // },
    {
        name: "guestDashboard",
        path: "/guestdashboard",
        element: <GuestLayout />
    }
]
