import React from "react"
import { Box } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import { formatNumber } from "../../../constant/DollorFormats"

function GridTab({ columns, rows, data, totalValue, ...props }) {
    const getRowId = (row) => {
        return row.transactionId // Assuming transactionId is unique
    }

    console.log(rows, columns, "rowwwwwwww")
    return (
        <Box
            padding="0px 10px"
            className
            sx={{
                "& .MuiDataGrid-root": {
                    border: "none",
                    borderBottom: "none"
                },
                "& .MuiDataGrid-cellContent": {
                    fontFamily: "Mona Sans !important",
                    fontSize: "14px",
                    paddingBlock: "2px"
                },
                "& .MuiDataGrid-row:nth-of-type(even)": {
                    backgroundColor: "#F1F1F1",
                    borderRadius: "8px"
                },
                "& .MuiDataGrid-cell": {
                    border: "none"
                },
                "&.MuiDataGrid-cell:focus": {
                    outline: "none !important"
                },
                "&.MuiDataGrid-actionsCell": {
                    boxSizing: "none !important",
                    gridGap: "1px !important"
                },
                "& .MuiDataGrid-columnHeaderTitle": {
                    fontFamily: "Mona Sans",
                    // fontWeight: "bold",
                    fontSize: "12px"
                },
                "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: "#000000",
                    color: "white",

                    "& .MuiCheckbox-root": {
                        color: "white"
                        // background: '#FFFFF'
                    }
                },
                "& .MuiDataGrid-sortIcon": {
                    display: "none"
                }
            }}
        >
            <DataGrid
                disableColumnMenu
                sx={{
                    fontFamily: "Mona Sans",
                    "& .MuiDataGrid-iconSeparator": {
                        display: "none !important"
                    },
                    "& .MuiDataGrid-cellContent": {
                        fontFamily: "Mona Sans !important"
                    },
                    "& .MuiDataGrid-columnHeaderTitle": {
                        fontFamily: "Mona Sans",
                        fontWeight: "bold"
                    },
                    "&.MuiDataGrid-cell--actions": {
                        boxSizing: "none !important",
                        display: "flex",
                        gridGap: "1 !important"
                    },
                    "&.MuiDataGrid-cell:focus": {
                        outline: "none !important"
                    },
                    "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
                        width: "0.4em"
                    },
                    "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track": {
                        background: "#f1f1f1"
                    },
                    "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
                        backgroundColor: "#6A3BE2"
                    },
                    "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover":
                        {
                            background: "#555"
                        }
                }}
                {...props}
                rows={rows}
                columns={columns}
                getRowId={getRowId}
                autoHeight={true}
                rowHeight={30}
                headerHeight={32}
                hideFooter={true}
                disableColumnSelector
            />
            <div
                style={{
                    background: "black",
                    display: "flex",
                    justifyContent: "flex-end",
                    borderRadius: "0px 0px 4px 4px",
                    fontFamily: "Mona Sans",
                    fontSize: "13px",
                    marginRight: "10px"
                }}
            >
                <p
                    style={{
                        color: "white",
                        paddingBlock: "2px",
                        marginBlock: "0px",
                        paddingRight: "10px"
                    }}
                >
                    Total Value($) {`US $${formatNumber(Number(totalValue))}`}
                </p>
            </div>
        </Box>
    )
}

export default GridTab
