/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */
import * as React from "react"
import { useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import {
    setSelectedHni,
    setIsLoading,
    setResponseList,
    setNotificationClick,
    setTableData,
    setGraphData,
    setStockData,
    setChartData,
    setDisplayType
} from "../../../../redux/slices/ChatGptSlice"

import {
    Autocomplete,
    Typography,
    Grid,
    TextField,
    Box,
    Button
} from "@mui/material"

import { styled } from "@mui/system"
import { formatDate } from "../../../common/Utils/DateFormat"

export default function Portfolio() {
    const { t } = useTranslation()

    let auth_header = ""
    if (JSON.parse(window.localStorage.getItem("user"))?.idToken) {
        const user = window.localStorage.getItem("user")
        if (user) {
            auth_header = `Bearer ${JSON.parse(user).idToken}`
        }
    }
    let headers = {
        authorization: auth_header,
        env: process.env.REACT_APP_DOMAIN_NAME,
        "content-type": "application/json"
    }
    const base_url = "https://gaia.kairoswealth.com/"

    const dispatch = useDispatch()

    const hniList = useSelector((state) => state.chatGpt.hniList)
    const selectedHni = useSelector((state) => state.chatGpt.selectedHni)
    const isLoading = useSelector((state) => state.chatGpt.isLoading)
    const [buttonsDisabled, setButtonsDisabled] = useState(true)

    const handleHniChange = (clientId) => {
        dispatch(setSelectedHni(clientId))
        setButtonsDisabled(false)
    }

    const handleGetRecommendationsClick = async (event) => {
        console.log("GET recommendations")
        dispatch(setNotificationClick(false))
        dispatch(setTableData(""))
        dispatch(setGraphData({}))
        dispatch(setStockData({}))
        dispatch(setChartData({}))
        dispatch(setIsLoading(true))
        dispatch(setDisplayType("MessagesList"))

        let url = base_url + "ask_recommendations/?clientId=" + selectedHni

        dispatch(setIsLoading(true))
        const response = await fetch(url, {
            method: "GET",
            headers: headers,
            credentials: "include"
        })

        const currentDate = new Date()

        let initialResponse = [
            {
                date: formatDate(currentDate, "DD-MMM-YYYY"),
                question: "",
                bookmark: "No",
                answer: "",
                chatGptId: "",
                ticker: ""
            }
        ]
        dispatch(setResponseList(initialResponse))
        let fullText = ""
        const reader = response.body.getReader()
        const decoder = new TextDecoder("utf-8")

        // process streaming fragments until done
        dispatch(setIsLoading(false))
        while (true) {
            const { done, value } = await reader.read()
            if (done) {
                break
            }
            // Convert the Uint8Array to a string and update the state
            const text = decoder.decode(value, { stream: true })
            let intermediateAnswer = [
                {
                    date: formatDate(currentDate, "DD-MMM-YYYY"),
                    question: "",
                    bookmark: "No",
                    answer: fullText + text,
                    chatGptId: "",
                    ticker: ""
                }
            ]
            dispatch(setResponseList(intermediateAnswer))
            fullText = fullText + text
        }
        let finalAnswer = [
            {
                date: formatDate(currentDate, "DD-MMM-YYYY"),
                question: "",
                bookmark: "No",
                answer: fullText,
                chatGptId: "999999",
                ticker: ""
            }
        ]
        dispatch(setResponseList(finalAnswer))
    }

    const handlePortfolioRebalancingAssetClassesClick = (event) => {
        console.log("GET Portfolio Rebalancing Asset Classes")
        console.log({ selectedHni })
        let url =
            base_url +
            "ask_rebalancing/?clientId=" +
            selectedHni +
            "&type=assets"
        fetch(url, {
            method: "GET",
            headers: headers
        })
            .then((response) => {
                return response.json()
            })
            .then((res) => {
                const currentDate = new Date()
                let updatedResponseList = [
                    {
                        date: formatDate(currentDate, "DD-MMM-YYYY"),
                        question: "Robo-advisor (Asset Classes)",
                        bookmark: "No",
                        answer: res,
                        ticker: ""
                    }
                ]
                dispatch(setResponseList(updatedResponseList))
            })
            .catch((e) => {
                toast.error(
                    "There has been an error processing your request. Please contact your administrator. Error code 710"
                )
                console.log({ e })
            })
    }

    return (
        <>
            <Grid
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                sx={{ px: 1, mb: 1 }}
            >
                <Typography color="white" mb={0}>
                    {t("Select a Client's portfolio")}
                </Typography>
            </Grid>
            <Autocomplete
                id="hni-selector"
                width="100%"
                size="small"
                sx={{ px: 1 }}
                options={hniList.map((hni) => hni.firstName + hni.lastName)}
                renderInput={(params) => (
                    <TextField {...params} label={t("Client")} />
                )}
                onChange={(event, value) => {
                    const selectedClientId = hniList.find(
                        (hni) => hni.firstName + hni.lastName === value
                    )?.userId
                    handleHniChange(selectedClientId)
                }}
            />

            {buttonsDisabled ? (
                <Typography variant="caption" px={1} sx={{ color: "gray.600" }}>
                    {t("Please select a client")}
                </Typography>
            ) : null}
            {/** Action buttons */}

            <Box px={1} pb={4} mt={2}>
                <Button
                    sx={{ mb: 2 }}
                    color="brand"
                    disabled={buttonsDisabled}
                    fullWidth
                    onClick={handleGetRecommendationsClick}
                    variant="contained"
                >
                    {t("Get Portfolio Recommendations")}
                </Button>
                <Button
                    fullWidth={true}
                    disabled={buttonsDisabled}
                    color="brand"
                    onClick={handlePortfolioRebalancingAssetClassesClick}
                    variant="outlined"
                >
                    {t("Robo-Advisor (Asset Classes)")}
                </Button>
            </Box>
        </>
    )
}
