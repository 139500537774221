import { createSlice } from "@reduxjs/toolkit"
import i18next from "i18next"

const ChatGptSlice = createSlice({
    name: "chatGpt",
    initialState: {
        adminList: [],
        bookmark: false,
        bookmarkList: [],
        bookmarkMessage: "",
        bookmarkStatusChange: false,
        chartData: {},
        close: "",
        folders: [],
        files: [],
        historyMessages: [],
        hniList: [],
        isCreateFolderModalOpen: false,
        isUploadDocumentModalOpen: false,
        isDeleteFolderModalOpen: false,
        isDeleteDocumentModalOpen: false,
        isDeleteFolderDisabled: true,
        isCompareCheckboxChecked: false,
        isLoading: false,
        notificationClick: false,
        notificationCount: 0,
        openAdmin: false,
        openHni: false,
        openClient: false,
        paginationObj: {},
        page: 0,
        privateEquity: [],
        responseList: [],
        row: null,
        receivedNotification: [],
        selectedButton: i18next.t("Stocks"),
        englishSelectedButton: "Stocks",
        selectedPrimaryStock: "",
        selectedSecondaryStock: "",
        selectedFolder: "",
        selectedFile: "",
        sent: false,
        selectedHni: "",
        stockGraphPeriod: i18next.t("daily"),
        totalCount: "",
        tickersList: [],
        uploadedFiles: [],
        sentNotificationList: [],
        sentAdminList: [],
        sentHniList: [],
        anchorEl: null,
        selectedTool: "",
        terminalHistory: {},
        selectedTerminalResponse: {},
        displayHelpModal: false,
        tableDataState: "",
        graphData: {},
        stockData: {},
        displayType: "Suggestions",
        agentType: "finance"
    },
    reducers: {
        setBookmark: (state, action) => {
            state.bookmark = action.payload
        },
        setBookmarkList: (state, action) => {
            state.bookmarkList = action.payload
        },
        setHistoryMessages: (state, action) => {
            state.historyMessages = action.payload
        },
        setBookmarkMessage: (state, action) => {
            state.bookmarkMessage = action.payload
        },
        setClose: (state, action) => {
            state.close = action.payload
        },
        setpaginationObj: (state, action) => {
            state.paginationObj = action.payload
        },
        setPage: (state, action) => {
            state.page = action.payload
        },
        setTotalCount: (state, action) => {
            state.totalCount = action.payload
        },
        setBookmarkStatusChange: (state, action) => {
            state.bookmarkStatusChange = !state.bookmarkStatusChange
        },
        setNotificationClick: (state, action) => {
            state.notificationClick = action.payload
        },
        setNotificationCount: (state, action) => {
            state.notificationCount = action.payload
        },
        setReceivedNotification: (state, action) => {
            state.receivedNotification = action.payload
        },
        setSelectedButton: (state, action) => {
            state.selectedButton = action.payload
        },
        setEnglishSelectedButton: (state, action) => {
            state.englishSelectedButton = action.payload
        },
        setSelectedPrimaryStock: (state, action) => {
            state.selectedPrimaryStock = action.payload
        },
        setSelectedSecondaryStock: (state, action) => {
            state.selectedSecondaryStock = action.payload
        },
        setSelectedFolder: (state, action) => {
            state.selectedFolder = action.payload
        },
        setFolders: (state, action) => {
            state.folders = action.payload
        },
        setFiles: (state, action) => {
            state.files = action.payload
        },
        setTickersList: (state, action) => {
            state.tickersList = action.payload
        },
        setIsCreateFolderModalOpen: (state, action) => {
            state.isCreateFolderModalOpen = action.payload
        },
        setIsUploadDocumentModalOpen: (state, action) => {
            state.isUploadDocumentModalOpen = action.payload
        },
        setIsDeleteFolderModalOpen: (state, action) => {
            state.isDeleteFolderModalOpen = action.payload
        },
        setIsDeleteDocumentModalOpen: (state, action) => {
            state.isDeleteDocumentModalOpen = action.payload
        },
        setUploadedFiles: (state, action) => {
            state.uploadedFiles = action.payload
        },
        setPrivateEquity: (state, action) => {
            state.privateEquity = action.payload
        },
        setIsDeleteFolderDisabled: (state, action) => {
            state.isDeleteFolderDisabled = action.payload
        },
        setIsCompareCheckboxChecked: (state, action) => {
            state.isCompareCheckboxChecked = action.payload
        },
        setSelectedFile: (state, action) => {
            state.selectedFile = action.payload
        },
        setStockGraphPeriod: (state, action) => {
            state.stockGraphPeriod = action.payload
        },
        setChartData: (state, action) => {
            console.log("Setting chartData")
            state.chartData = action.payload
        },
        setOpenAdmin: (state, action) => {
            state.openAdmin = action.payload
        },
        setOpenHni: (state, action) => {
            state.openHni = action.payload
        },
        setOpenClient: (state, action) => {
            state.openClient = action.payload
        },
        setRow: (state, action) => {
            state.row = action.payload
        },
        setAdminList: (state, action) => {
            state.adminList = action.payload
        },
        setHniList: (state, action) => {
            state.hniList = action.payload
        },
        setSent: (state, action) => {
            state.sent = action.payload
        },
        setSelectedHni: (state, action) => {
            state.selectedHni = action.payload
        },
        setIsLoading: (state, action) => {
            state.isLoading = action.payload
        },
        setResponseList: (state, action) => {
            state.responseList = action.payload
        },
        setSentNotificationList: (state, action) => {
            state.sentNotificationList = action.payload
        },
        setSentAdminList: (state, action) => {
            state.sentAdminList = action.payload
        },
        setSentHniList: (state, action) => {
            state.sentHniList = action.payload
        },
        setAnchorEl: (state, action) => {
            state.anchorEl = action.payload
        },
        setSelectedTool: (state, action) => {
            state.selectedTool = action.payload
        },
        setTerminalHistory: (state, action) => {
            state.terminalHistory = action.payload
        },
        setSelectedTerminalResponse: (state, action) => {
            state.selectedTerminalResponse = action.payload
        },
        setDisplayHelpModal: (state, action) => {
            state.displayHelpModal = action.payload
        },
        setTableData: (state, action) => {
            state.tableDataState = action.payload
        },
        setGraphData: (state, action) => {
            state.graphData = action.payload
        },
        setStockData: (state, action) => {
            state.stockData = action.payload
        },
        setDisplayType: (state, action) => {
            state.displayType = action.payload
        },
        setAgentType: (state, action) => {
            state.agentType = action.payload
        }
    }
})
export const {
    setTotalCount,
    setPage,
    setsearchParams,
    setpaginationObj,
    storeFunction,
    setBookmark,
    setBookmarkList,
    setHistoryMessages,
    setBookmarkMessage,
    setClose,
    setBookmarkStatusChange,
    setNotificationClick,
    setNotificationCount,
    setReceivedNotification,
    setSelectedButton,
    setEnglishSelectedButton,
    setSelectedPrimaryStock,
    setSelectedSecondaryStock,
    setSelectedFolder,
    setFolders,
    setFiles,
    setTickersList,
    setIsCreateFolderModalOpen,
    setIsUploadDocumentModalOpen,
    setIsDeleteFolderModalOpen,
    setIsDeleteDocumentModalOpen,
    setUploadedFiles,
    setPrivateEquity,
    setIsDeleteFolderDisabled,
    setIsCompareCheckboxChecked,
    setSelectedFile,
    setStockGraphPeriod,
    setChartData,
    setOpenAdmin,
    setOpenHni,
    setOpenClient,
    setRow,
    setAdminList,
    setHniList,
    setSent,
    setSelectedHni,
    setIsLoading,
    setResponseList,
    setSentNotificationList,
    setSentAdminList,
    setSentHniList,
    setAnchorEl,
    setSelectedTool,
    setTerminalHistory,
    setSelectedTerminalResponse,
    setDisplayHelpModal,
    setTableData,
    setGraphData,
    setStockData,
    setDisplayType,
    setAgentType
} = ChatGptSlice.actions

export default ChatGptSlice.reducer
