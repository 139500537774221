import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Navigate, Outlet } from "react-router-dom"
import Layout from "../Layout/Layout"
import { AppLogout } from "../../routes/AppLogout"
import { fetchGeneralDropdowns } from "redux/slices/generalSlice"

const PrivateRoutes = () => {
    const dispatch = useDispatch()
    const { user, countries } = useSelector((state) => ({
        ...state.auth.user,
        countries: state.general.countries
    }))

    useEffect(() => {
        if (user !== null) {
            if (countries.length === 0) {
                dispatch(fetchGeneralDropdowns())
            }
        }
    }, [user, countries, dispatch])

    const userLocalObj = localStorage.getItem("user")
    return user !== null || userLocalObj !== null ? (
        <AppLogout>
            <Layout>
                <Outlet />
            </Layout>
        </AppLogout>
    ) : (
        <Navigate to="/" />
    )
}

export default PrivateRoutes
