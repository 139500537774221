import React from "react"
import Box from "@mui/material/Box"
import Modal from "@mui/material/Modal"
import { Typography, Button } from "@mui/material"
import IconButton from "@mui/material/IconButton"
import CloseIcon from "@mui/icons-material/Close"
import { TextareaAutosize } from "@mui/base"
import Grid from "@mui/material/Grid"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"

const EnquiryModel = ({
    setDescription,
    handleSubmit,
    selectedValue,
    handleSelectChange,
    addEnquiry,
    faqModelData,
    enquiryModelOpen,
    handleClose
}) => {
    const style = {
        position: "absolute",
        boxShadow: 24,
        top: "50%",
        left: "50%",
        right: "50%",
        transform: "translate(-50%, -50%)",
        width: "60vw",
        // height: "43vh",
        overflowY: "auto",
        margin: "auto",
        pt: 1.5,
        px: 2,
        pb: 1.5,
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        borderRadius: "8px",
        opacity: "1",
        bgcolor: "background.paper"
    }
    return (
        <div>
            <Modal
                hideBackdrop
                open={enquiryModelOpen}
                onClose={handleClose}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box sx={{ ...style, alignItems: "center" }}>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between"
                        }}
                    >
                        <Typography
                            id="child-modal-title"
                            variant="h6"
                            sx={{ fontWeight: "bold" }}
                        >
                            Enquiry Form
                        </Typography>
                        <IconButton onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                    {!addEnquiry && (
                        <Typography
                            sx={{
                                fontFamily: "Mona Sans",
                                textTransform: "capitalize"
                            }}
                        >
                            <span
                                style={{
                                    fontWeight: "bold",
                                    marginRight: "5px"
                                }}
                            >
                                Subject:
                            </span>
                            {faqModelData?.description.toLowerCase()}
                        </Typography>
                    )}
                    {addEnquiry && (
                        <Typography sx={{ fontFamily: "Mona Sans" }}>
                            <span
                                style={{
                                    fontWeight: "bold",
                                    marginRight: "5px"
                                }}
                            >
                                Subject:
                            </span>
                            <Select
                                value={selectedValue}
                                id="enquiry-simple-select"
                                onChange={handleSelectChange}
                                sx={{
                                    width: "200px",
                                    height: "30px"
                                }}
                                inputProps={{ "aria-label": "Without label" }}
                            >
                                {faqModelData?.map((val) => {
                                    return (
                                        <MenuItem key={val?.id} value={val?.id}>
                                            {val?.description}
                                        </MenuItem>
                                    )
                                })}

                                {/* <MenuItem value={10}>Ten</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem> */}
                            </Select>
                        </Typography>
                    )}
                    <Grid item xs={12} lg={12}>
                        <Typography
                            variant="h6"
                            sx={{
                                mb: 1,
                                fontFamily: "Mona Sans",
                                paddingTop: "13px"
                            }}
                        >
                            Enquiry
                        </Typography>

                        <TextareaAutosize
                            size="small"
                            type="text"
                            step="any"
                            style={{
                                width: "100%",
                                height: "75px",
                                // background: "#F9F9F9",
                                border: "1px solid #DDDDDD",
                                borderRadius: "8px"
                                //  background: '#F9F9F9'
                            }}
                            maxRows={3}
                            aria-label="maximum height"
                            maxLength={1000}
                            placeholder="1000 Character Limit"
                            InputProps={{
                                readOnly: true
                            }}
                            onChange={(event) =>
                                setDescription(event.target.value)
                            }
                            id={"description"}
                            name={"description"}
                        />
                    </Grid>

                    <Box
                        sx={{ flexGrow: 1, flexDirection: "row" }}
                        xs={12}
                        lg={4}
                        md={4}
                    >
                        <Grid container spacing={3} sx={{ marginTop: "3px" }}>
                            <Grid item>
                                <Button
                                    sx={{
                                        width: "100px",
                                        height: "32px",
                                        textAlign: "left",
                                        background: "#683AE0",
                                        textTransform: "capitalize"
                                    }}
                                    variant="contained"
                                    type="submit"
                                    onClick={handleSubmit}
                                >
                                    {"Submit"}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    style={{
                                        width: "100px",
                                        height: "32px",
                                        border: "1px solid #767676",
                                        borderRadius: "6px",
                                        textTransform: "capitalize",
                                        backgroundColor: "transparent",
                                        color: "#000",
                                        opacity: 1
                                    }}
                                    onClick={handleClose}
                                >
                                    {"Cancel"}
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Modal>
        </div>
    )
}

export default EnquiryModel
