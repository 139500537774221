import {
    SelectWrapper,
    DateWrapper,
    TextFieldWrapper,
    CheckboxWrapper
} from "components/common/Ui/Form"
import { Field } from "formik"
import AutoCompleteWrapper from "./AutoCompleteWrapper"

const FormField = ({
    name,
    label,
    type,
    options,
    multiline,
    handleChange,
    ...delegated
}) => {
    switch (type) {
        case "select":
            return (
                <SelectWrapper
                    label={label}
                    name={name}
                    id={name}
                    options={options || []}
                    handleChange={handleChange}
                    {...delegated}
                />
            )
        case "date":
            return (
                <DateWrapper
                    id={name}
                    name={name}
                    label={label}
                    {...delegated}
                />
            )
        case "checkbox":
            return (
                <CheckboxWrapper
                    id={name}
                    name={name}
                    label={label}
                    {...delegated}
                />
            )
        case "creatable":
            return (
                <Field
                    name={name}
                    label={label}
                    options={options}
                    component={AutoCompleteWrapper}
                    required={delegated.required}
                />
            )
        default:
            return (
                <TextFieldWrapper
                    id={name}
                    type={type}
                    name={name}
                    label={label}
                    {...delegated}
                />
            )
    }
}
export default FormField
