import { useState, useEffect } from "react"
import { getChatAccessToken } from "../../../../services"
// eslint-disable-next-line
import {
    setUnreadCount,
    setMoxoAccessToken
} from "../../../../redux/slices/navSlice"
import { useDispatch, useSelector } from "react-redux"
import jQuery from "jquery"
import { AES256_GCM_decrypt } from "../../../../constant/EncryptionDecryptionUtils"

const demo_domain = process.env.REACT_APP_MOXO_DOMAIN
const client_id = process.env.REACT_APP_MOXO_CLIENT_ID
const client_secret = process.env.REACT_APP_MOXO_CLIENT_SECRET
const org_id = process.env.REACT_APP_MOXO_ORG_ID

let linkedSuccessfully = false
let mepwin = null

export default function MoxoChatContainer() {
    const tokenStore = useSelector((state) => state.nav.moxoAccessToken)
    const [accessToken, setAccessToken] = useState(undefined)
    const dispatch = useDispatch()
    const user = JSON.parse(window.localStorage.getItem("user"))
    let client_email = user?.email
    let userRole = user?.userTypeId?.description

    useEffect(() => {
        if (!tokenStore) {
            onloadHandler()
        } else if (tokenStore) {
            Timeline(tokenStore)
        }
        // eslint-disable-next-line
    }, [])

    const onloadHandler = async () => {
        const token = await generateClientAccessToken()
        if (token) {
            Timeline(token)
        } else {
            setAccessToken(undefined)
        }
    }

    function notifyMe() {
        if (!("Notification" in window)) {
            // Check if the browser supports notifications
            alert("This browser does not support desktop notification")
        } else if (Notification.permission === "granted") {
            // Check whether notification permissions have already been granted;
            // if so, create a notification
            // eslint-disable-next-line
            const notification = new Notification("Hi there!")
            // …
        } else if (Notification.permission !== "denied") {
            // We need to ask the user for permission
            Notification.requestPermission().then((permission) => {
                // If the user accepts, let's create a notification
                if (permission === "granted") {
                    // eslint-disable-next-line no-unused-vars
                    const notification = new Notification("Hi there!")
                    // …
                }
            })
        }
        // At last, if the user has denied notifications, and you
        // want to be respectful there is no need to bother them anymore.
    } //notifyMe

    const Timeline = async (token) => {
        if (linkedSuccessfully && mepwin !== null) {
            if (userRole === "Kairos Admin" || userRole === "Entity Admin") {
                window.mepsdk.showMEPClientDashboard("#box", {
                    hideBrandingHeader: true
                })
            } else {
                window.mepsdk.showMEPWindowLite("#box", { hideHeader: true })
            }
        } else {
            window.mepsdk.setup({
                baseDomain: demo_domain, //replace with YOUR_MOXTRA_DOMAIN
                deployDirectory: "web",
                accessToken: accessToken || token // replace with the access token of the user
            })

            window.mepsdk.setConfig({
                disableDownload: true,
                disableChangeChatName: true,
                hideInactiveRelationChat: false,
                disableEmoji: false,
                disableUserPresence: true,
                hideChatNotificationsSetting: true
            })

            window.mepsdk.subscribeUnreadCount(function (count) {
                dispatch(setUnreadCount(count))
            })

            if (userRole === "Kairos Admin" || userRole === "Entity Admin") {
                mepwin = window.mepsdk.showMEPClientDashboard("#box", {
                    hideBrandingHeader: true
                })
            } else {
                mepwin = window.mepsdk.showMEPWindowLite("#box", {
                    hideHeader: true
                })
            }

            await notifyMe()
            if (mepwin) {
                mepwin.onNotification(function (data) {
                    console.log(data.boardId)
                })

                mepwin.onTimelineRendered(function () {
                    linkedSuccessfully = true
                })

                mepwin.onTapLogConversation(function (payload) {
                    // payload: {orgId: string, chatId: string}
                    alert(
                        "onTapLogConveration " +
                        payload.orgId +
                        " " +
                        payload.chatId
                    )
                })

                mepwin.onUnreadCountChange(function (count) {
                    dispatch(setUnreadCount(count))
                    jQuery(".num").text(count)
                })

                mepwin.onTapTransactionButton(function (info) {
                    const args_string = JSON.stringify(info)
                    console.log(args_string)
                })
            }
        }
    }

    const generateClientAccessToken = async () => {
        try {
            var input = {
                client_id: client_id,
                client_secret: client_secret,
                unique_id: await AES256_GCM_decrypt({
                    data: { encryptedValue: client_email }
                }),
                org_id: org_id
            }
            let url = demo_domain + "/v1/core/oauth/token"
            const response = await getChatAccessToken(url, input)
            response && setAccessToken(response?.access_token)
            response && dispatch(setMoxoAccessToken(response?.access_token))
            return response?.access_token
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div>
            <div id="box"></div>
        </div>
    )
}
