import {
    RadioGroup,
    FormControlLabel,
    Radio,
    Box,
    Typography,
    FormHelperText
} from "@mui/material"
import { useField } from "formik"

export default function RadioGroupWrapper({
    options,
    field,
    form: { touched, errors },
    children,
    ...props
}) {
    const name = field.name
    return (
        <>
            <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                {...field}
                {...props}
                name={name}
            >
                {renderOptions({ fieldName: name, options })}
            </RadioGroup>
            {touched[name] && errors[name] && (
                <FormHelperText error>{errors[name]}</FormHelperText>
            )}
        </>
    )
}

const renderOptions = ({ fieldName, options }) => {
    return options.map((option) => (
        <RadioButton
            key={option.value}
            value={option.value}
            label={option.label}
            subtext={option.subtext}
            name={fieldName}
        />
    ))
}

const RadioButton = ({ name, value, label, subtext }) => {
    const [field] = useField(name)
    return (
        <Box
            sx={{
                border: "2px solid",
                borderRadius: 1,
                py: 2,
                px: 3,
                mb: 2,
                borderColor: field.value === value ? "brand.main" : "gray.200"
            }}
        >
            <FormControlLabel
                sx={{ display: "block" }}
                value={value}
                control={<Radio color="brand" />}
                label={label}
            />
            <Typography variant="body2" sx={{ color: "gray.500" }}>
                {subtext}
            </Typography>
        </Box>
    )
}
