import { useDispatch, useSelector } from "react-redux"
import { goBack } from "redux/slices/selfOnboardSlice"

import { Box } from "@mui/material"
import { Button } from "components/common/Ui/Form"
import { NavigateBefore, NavigateNext } from "@mui/icons-material"

const FormAction = ({ isLoading }) => {
    const dispatch = useDispatch()
    const { activeStep, totalStepsCount } = useSelector(
        (state) => state.selfOnboard
    )

    const handleBackBtn = () => {
        dispatch(goBack())
    }

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "row",
                pt: 2,
                justifyContent: "space-between",
                alignItems: "center"
            }}
        >
            <Button
                variant="outlined"
                disabled={activeStep === 0}
                onClick={handleBackBtn}
                sx={{ mr: 1 }}
                startIcon={NavigateBefore}
            >
                Back
            </Button>
            <Button
                variant="contained"
                endIcon={NavigateNext}
                type="submit"
                disabled={isLoading}
            >
                {isLoading
                    ? "Saving..."
                    : activeStep === totalStepsCount - 1
                      ? "Finish"
                      : "Next"}
            </Button>
        </Box>
    )
}
export default FormAction
