import { useTheme } from "@emotion/react"
import { useMediaQuery } from "@mui/material"
import { RESPONSIVE_MODES } from "constant/globalConstants"
import { useEffect } from "react"
import { useState } from "react"

const { SM, MD, LG, XL } = RESPONSIVE_MODES

export const useResponsiveMode = () => {
    const theme = useTheme()
    const isTabletMode = useMediaQuery(theme.breakpoints.down("md"))
    const isMobileMode = useMediaQuery(theme.breakpoints.down("sm"))
    const isDesktopMode = useMediaQuery(theme.breakpoints.down("lg"))
    const [mode, setMode] = useState(XL)

    useEffect(() => {
        if (isMobileMode) setMode(SM)
        else if (isTabletMode) setMode(MD)
        else if (isDesktopMode) setMode(LG)
        else setMode(XL)
    }, [isTabletMode, isMobileMode, isDesktopMode])

    return { mode }
}
