import { Axios1 } from "./config" // eslint-disable-next-line

let productIDVALUE = {
    productIDDetails: {
        vendorId: "",
        productServiceId: "",
        productServiceName: "",
        productServiceDescription: "",
        productServiceTypeId: "",
        primaryCategoryId: "",
        secondaryCategoryId: "",
        startDate: "",
        endDate: "",
        returnsLevel: "",
        riskLevel: "",
        liquidity: "",
        hnisInvested: "",
        taxStatus: "",
        tags: "",
        regionId: "",
        investmentTerm: "",
        takeRate: "",
        takeRateParameterId: "",
        value: "",
        productServicePhotoUrl: ""
    }
}

function setProductIDDetails(value) {
    productIDVALUE.productIDDetails = value
}

export { productIDVALUE, setProductIDDetails }

export const productServicemanagementDropDownList = async (moduleId) => {
    const promise1 = Axios1.get(
        "/api/v1/productservice/getall-dropdown-productservice/" + moduleId
    )
    var resultService = await Promise.all([promise1]).then(function (values) {
        return values
    })
    return resultService
}

export const productServiceManagementGetDatalist = async (
    passingValue,
    moduleId
) => {
    var resultService2 = await Axios1.post(
        `/api/v1/productservice/getall-products-services/` + moduleId,
        passingValue
    )
    return resultService2.data
}

export const productServiceManagementGetDataByID = async (id, moduleId) => {
    var resultService = await Axios1.get(
        `/api/v1/productservice/get-productservice/${moduleId}/${id}`
    )
    if (resultService.data.status) {
        await setProductIDDetails(resultService.data.body)
        return productIDVALUE.productIDDetails
    }
}

export const addProductsServiceNew = async (passingValue, moduleId) => {
    var resultService = await Axios1.post(
        `/api/v1/productservice/add-productservice/` + moduleId,
        passingValue
    )
    return resultService
}

export const editProductservicesUpdate = async (passingValue, moduleId) => {
    var resultService = await Axios1.post(
        `/api/v1/productservice/update-productservice/` + moduleId,
        passingValue
    )
    return resultService
}

export const deleteProductserviceId = async (passingValue, moduleId) => {
    var resultService = await Axios1.post(
        `/api/v1/productservice/update-productservice-status/` + moduleId,
        passingValue
    )

    return resultService
}

export const applyPromoteByProductServiceID = async (
    passingValue,
    moduleId
) => {
    var resultService = await Axios1.post(
        `/api/v1/productservice/promote-by/` + moduleId,
        passingValue
    )
    return resultService
}

export const startPromotionAPI = async (passingValue, moduleId) => {
    var resultService = await Axios1.post(
        `/api/v1/productservice/start-promote/` + moduleId,
        passingValue
    )
    return resultService
}

export const getAllProductTags = async (moduleId) => {
    var resultService = await Axios1.get(
        `/api/v1/productservice/get-all-product-tags/` + moduleId
    )
    return resultService
}

export const getAllTagsandLocation = async (moduleId) => {
    var resultService = await Axios1.get(
        `/api/v1/productservice/get-all-tags/` + moduleId
    )
    return resultService
}

export const stopPromotionAPI = async (passingValues, moduleId) => {
    var resultService = await Axios1.post(
        `/api/v1/productservice/update-promote/` + moduleId,
        passingValues
    )
    return resultService
}

export const rejectwithReasonsorApproval = async (passingValues, moduleId) => {
    var resultService = await Axios1.post(
        `/api/v1/productservice/submit-productservice/` + moduleId,
        passingValues
    )
    return resultService
}

export const resubbmitforApproval = async (passingValue, moduleId) => {
    var resultService = await Axios1.post(
        `/api/v1/productservice/resubmit-productservice/` + moduleId,
        passingValue
    )
    return resultService
}
export const downloadManagement = async (passingValue, moduleId) => {
    const payload = await Axios1.post(
        `/api/v1/productservice/productservice/export/excel/` + moduleId,
        passingValue,
        { responseType: "blob" }
    )
    return payload
}

export const searchFilterAutoComplete = async (searchParams, PID) => {
    var resultService = await Axios1.get(
        `api/v1/productservice/alphavantage-search-endpoint/financialproducts/${searchParams}/${PID != null ? PID : 0}`
    )
    return resultService
}

export const assetAllocationAPI = async (passingValue, moduleId) => {
    var resultService = await Axios1.post(
        `/api/v1/productservice/update-available-chosen-entity/` + moduleId,
        passingValue
    )
    return resultService
}

export const submitDocProductService = async (formData) => {
    var resultService = await Axios1.post(
        `/api/v1/productservice/upload-file/lifestyleproducts`,
        formData,
        {
            headers: { "content-type": "multipart/form-data" }
        }
    )
    return resultService
}

export const downloadDOCUMENTSProductService = async (passingValue) => {
    var resultAPI = await Axios1.post(
        "/api/v1/productservice/download-file/lifestyleproducts",
        passingValue,
        { responseType: "arraybuffer" }
    )
    return resultAPI
}

export const deleteDocumentsProductService = async (passingValues) => {
    var resultService = await Axios1.post(
        `/api/v1/productservice/delete-file/lifestyleproducts`,
        passingValues
    )
    return resultService
}

export const submitDocProductServiceEdit = async (formData) => {
    var resultService = await Axios1.post(
        `/api/v1/productservice/upload-file-edit/lifestyleproducts`,
        formData,
        {
            headers: { "content-type": "multipart/form-data" } //transformRequest: formData => formData,
        }
    )
    return resultService
}

export const downloadDOCUMENTSProductServiceEdit = async (passingValue) => {
    var resultAPI = await Axios1.post(
        "/api/v1/productservice/download-file-edit/lifestyleproducts",
        passingValue,
        { responseType: "arraybuffer" }
    )
    return resultAPI
}

export const deleteDocumentsProductServiceEdit = async (passingValues) => {
    var resultService = await Axios1.post(
        `/api/v1/productservice/delete-file-edit/lifestyleproducts`,
        passingValues
    )
    return resultService
}
