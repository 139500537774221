import Chart from "chart.js/auto"
import { CategoryScale } from "chart.js"
import { useState, useEffect } from "react"
import { Bar } from "react-chartjs-2"
import { useMediaQuery } from "@mui/material"
Chart.register(CategoryScale)

export default function BARWITHLINE(props) {
    // generate the stacked data taking i+1 value
    let stackedData = []
    // check first if we have data
    if (props?.data?.data.length > 0 && props?.data?.data) {
        for (let i = 0; i < props?.data?.data.length - 1; i++) {
            stackedData.push(props?.data?.data[i + 1] - props?.data?.data[i])
        }
    }

    // format labels to show only the month 3 letters
    let labels = props?.data?.label
    if (labels.length > 0) {
        labels = labels.map((label) => {
            return label.split(" ")[0]
        })
    }

    const [chartData] = useState({
        responsive: true,
        labels: labels,
        datasets: [
            {
                type: "bar",
                backgroundColor: "#6A3BE0",
                data: [...props?.data?.data],
                datalabels: {
                    labels: {
                        title: null
                    }
                },
                stack: "Stack 0"
            },
            {
                type: "bar",
                backgroundColor: function (context) {
                    var chart = context.chart
                    var ctx = chart.ctx
                    var gradient = ctx.createLinearGradient(
                        0,
                        0,
                        0,
                        chart.height
                    )
                    gradient.addColorStop(0, "#6A3BE0")
                    gradient.addColorStop(0.6, "#FFFFFF")
                    return gradient
                },
                data: stackedData,
                datalabels: {
                    labels: {
                        title: null
                    }
                },
                stack: "Stack 0"
            }
        ]
    })
    const isMobileOrTablet = useMediaQuery("(max-width: 1279px)")

    const [containerHeight, setContainerHeight] = useState("70%")

    const getContainerHeight = () => {
        const windowHeight = window.innerHeight
        if (windowHeight >= 1200 && windowHeight <= 1299) {
            return "80%"
        } else if (windowHeight >= 1300 && windowHeight <= 1400) {
            return "99%"
        } else if (windowHeight >= 1400 && windowHeight <= 2000) {
            return "100%"
        } else if (windowHeight >= 2001 && windowHeight <= 3000) {
            return "100%"
        } else if (windowHeight >= 800 && windowHeight <= 900) {
            return "75%"
        } else if (windowHeight >= 700 && windowHeight <= 799) {
            return "73%"
        } else if (windowHeight <= 500) {
            return "90%"
        }
        return "90%" // Default height
    }

    const [chartInstance, setChartInstance] = useState(null)

    // Update height on window resize
    useEffect(() => {
        const handleResize = () => {
            const newHeight = getContainerHeight()
            setContainerHeight(newHeight)
        }

        // Initial call to set height
        handleResize()

        // Event listener for window resize
        window.addEventListener("resize", handleResize)

        // Cleanup function to remove event listener
        return () => {
            if (chartInstance) {
                chartInstance.destroy()
            }
            window.removeEventListener("resize", handleResize)
        } // eslint-disable-next-line
    }, [])
    // eslint-disable-next-line
    const footer = (tooltipItems) => {
        return tooltipItems[0].label
    }
    const getTooltipLabel = (tooltipItem) => {
        if (!tooltipItem.dataset.type) return `US ($) ${tooltipItem.raw}`

        return `US ($) ${tooltipItem.formattedValue}`
    }
    return (
        <div
            style={{
                height: "100%",
                minHeight: "300px",
                maxHeight: "400px",
                width: "100%",
                display: "flex",
                justifyContent: "center"
            }}
        >
            <Bar
                data={chartData}
                height={!isMobileOrTablet ? containerHeight : "90%"}
                // height={'300px'} //props?.type === "admin" ? containerHeight :
                width={"100%"}
                getElementAtEvent={(elements) => {
                    if (elements.length > 0) {
                        const chart = elements[0].chart
                        setChartInstance(chart)
                    }
                }}
                options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        title: {
                            display: false
                        },
                        legend: {
                            display: false
                        },
                        tooltip: {
                            displayColors: false,
                            callbacks: {
                                label: function (tooltipItem) {
                                    return getTooltipLabel(tooltipItem)
                                }
                            }
                        }
                    },
                    scales: {
                        x: {
                            ticks: {
                                color: "#495057"
                            },
                            grid: {
                                color: "#ffffff"
                            }
                        },
                        y: {
                            ticks: {
                                color: "black",
                                callback: function (value) {
                                    if (value >= 1e9) {
                                        return value / 1e9 + "B"
                                    } else if (value >= 1e6) {
                                        return value / 1e6 + "M"
                                    } else if (value >= 1e3) {
                                        return value / 1e3 + "K"
                                    } else {
                                        return value
                                    }
                                }
                            },
                            grid: {
                                color: "#F6F6F6",
                                borderDash: [5, 5]
                            }
                        }
                    }
                }}
            />
        </div>
    )
}
