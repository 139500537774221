export function clearLocalStorageExceptPersistRoot() {
    const persistRoot = localStorage.getItem("persist:root")

    // Clear all localStorage items
    localStorage.clear()

    // Restore "persist:root" item
    if (persistRoot !== null) {
        localStorage.setItem("persist:root", persistRoot)
    }
}
