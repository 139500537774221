import { configureStore, combineReducers } from "@reduxjs/toolkit"
import logger from "redux-logger"
import storage from "redux-persist/lib/storage" // defaults to localStorage for web
import { persistStore, persistReducer } from "redux-persist"
//import { PersistGate } from "redux-persist/integration/react";
import thunk from "redux-thunk"
import AuthReducer from "./slices/authSlice"
import UserManagementReducer from "./slices/userManagement"
import navReducer from "./slices/navSlice"
import loadingSlice from "./slices/loadingSlice"
import ChatGptSlice from "./slices/ChatGptSlice"
import modalSlice from "./slices/dashboardModalSlice"
import globalCurrencySlice from "./slices/globalCurrencySlice"
import productSlice from "./slices/productSlice"
import generalSlice from "./slices/generalSlice"
import dealRoomSlice from "./slices/dealRoomSlice"
import dashboardReducer from "./slices/dashboardSlice"
import hniProfileSlice from "./slices/hniProfileSlice"
import productServiceReducer from "./slices/ProductServiceSlices"
import selfOnboardSlice from "./slices/selfOnboardSlice"
import prospectProfileSlice from "./slices/prospectProfileSlice"
import relatedPartySlice from "./slices/relatedPartySlice"

// Define persist configuration
const persistConfig = {
    key: "root",
    storage,
    whitelist: ["dashboard", "productService", "dealRoom"] // only dashboard will be persisted
}

const rootReducer = combineReducers({
    auth: AuthReducer,
    userManagement: UserManagementReducer,
    nav: navReducer,
    loader: loadingSlice,
    chatGpt: ChatGptSlice,
    modal: modalSlice,
    globalCurrency: globalCurrencySlice,
    product: productSlice,
    general: generalSlice,
    dealRoom: dealRoomSlice,
    dashboard: dashboardReducer,
    productService: productServiceReducer,
    hniProfile: hniProfileSlice,
    selfOnboard: selfOnboardSlice,
    prospectProfile: prospectProfileSlice,
    relatedParty: relatedPartySlice
})

// Create a persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer)
// Configure the store
const store = configureStore({
    reducer: persistedReducer,
    middleware: [thunk, logger]
})

// Create a persistor and export the Store and  Wrapping with persistor(store)
const persistor = persistStore(store)

export { store, persistor }
