import React from "react"
import { Box, Typography, Grid } from "@mui/material"
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"
import { motion } from "framer-motion"
import { useTranslation } from "react-i18next"
import {
    setBookmarkList,
    setBookmarkMessage,
    setClose,
    setSelectedPrimaryStock
} from "../../../../redux/slices/ChatGptSlice"
import { updateBookmarkStatus } from "../../../../services/index"
import { useSelector, useDispatch } from "react-redux"

export default function Bookmarks({ bookmarkList }) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const user = JSON.parse(localStorage.getItem("user"))
    const targetValue = useSelector((state) => state.nav.targetValue)
    const selectedButton = useSelector((state) => state.chatGpt.selectedButton)
    const selectedPrimaryStock = useSelector(
        (state) => state.chatGpt.selectedPrimaryStock
    )

    const handleBookmark = (list, index) => {
        dispatch(setBookmarkMessage(list?.question))
        // check if list.ticker exists
        if (list?.ticker) {
            dispatch(setSelectedPrimaryStock(list?.ticker))
        }
        dispatch(setClose(list?.chatGptId))
    }

    const handleDeleteBookmark = (chatGptId, data) => {
        let obj = {
            chatGptId: chatGptId,
            bookmark: "No",
            userId: user?.userId,
            streamedData: data.answer || "",
            ticker: selectedPrimaryStock || "",
            bookmarkType:
                targetValue === "ChatGpt" ? targetValue : selectedButton
        }
        updateBookmarkStatus(obj)
        let updatedBookmarksList = bookmarkList.filter(
            (item) => item.chatGptId !== chatGptId
        )
        dispatch(setBookmarkList(updatedBookmarksList))
    }

    return (
        <Grid display="flex" flexDirection="column" width="100%">
            <Typography
                pr={2}
                pl={2}
                pb={1.5}
                sx={{ fontSize: "18px", textAlign: "left" }}
            >
                {t("Bookmarks")}
            </Typography>
            {/** Display bookmarks */}
            {bookmarkList?.map((bookmark) => {
                if (bookmark.bookmarkType === t(selectedButton)) {
                    return (
                        <Box
                            key={bookmark?.chatGptId}
                            display="flex"
                            flexDirection="row"
                            sx={{
                                paddingX: 2,
                                cursor: "pointer",
                                marginBottom: 1
                            }}
                            onClick={() => handleBookmark(bookmark)}
                        >
                            <Typography
                                pt={0}
                                component={motion.div}
                                whileHover={{
                                    translateX: 5,
                                    transition: { duration: 0.3 }
                                }}
                                sx={{
                                    fontSize: "12px",
                                    overflow: "hidden",
                                    cursor: "pointer",
                                    border: "1px solid",
                                    borderColor: "gray.200",
                                    borderRadius: "8px",
                                    width: "100%",
                                    padding: "8px"
                                }}
                                display="flex"
                                flexDirection="row"
                                justifyContent="space-between"
                                width="100%"
                                title={bookmark?.question}
                            >
                                <>{bookmark?.question}</>
                                <DeleteOutlineIcon
                                    fontSize="small"
                                    sx={{
                                        cursor: "pointer"
                                    }}
                                    onClick={() =>
                                        handleDeleteBookmark(
                                            bookmark?.chatGptId,
                                            bookmark
                                        )
                                    }
                                />
                            </Typography>
                        </Box>
                    )
                } else {
                    return null
                }
            })}
        </Grid>
    )
}
