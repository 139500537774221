import { useField } from "formik"
import { InputAdornment, TextField } from "@mui/material"

const TextFieldWrapper = ({
    endAdornment,
    name,
    size = "small",
    readOnly = false,
    rows = 2,
    ...props
}) => {
    const [field, meta] = useField(name)

    const config = {
        ...field,
        ...props,
        fullWidth: true,
        size,
        rows,
        InputProps: {
            readOnly
        }
    }

    if (meta && meta.error && meta.touched) {
        config.error = true
        config.helperText = meta.error
    }

    return (
        <TextField
            sx={{
                font: "inherit",
                "& .MuiOutlinedInput-root": {
                    "& fieldset": {},
                    "&:hover fieldset": {
                        borderColor: "#7450da"
                    },
                    "&.Mui-focused fieldset": {
                        borderColor: "#7450da"
                    }
                }
            }}
            {...config}
            InputProps={{
                endAdornment: (
                    endAdornment ? <InputAdornment position="start">{endAdornment}</InputAdornment>: null
                )
            }}
        />
    )
}
export default TextFieldWrapper
