/* eslint-disable no-unused-vars */
import React from "react"
import {
    Grid,
    Typography,
    Box,
    useMediaQuery,
    CircularProgress
} from "@mui/material"
import GridTable from "./GridTable"
import MarketNews from "../../../common/MarketNews/MarketNews"
import DynamicDashboardCards from "../../../common/Grid/DynamicDashboardCards"
import PaginationComponent from "../../../common/Pagination/Pagination"
import BarChartController from "../../Dashboard/DashboardModalController/BarChartController"
import PieChartController from "../../Dashboard/DashboardModalController/PieChartController"
import SearchIcon from "@mui/icons-material/Search"
import {
    Search,
    SearchIconWrapper,
    StyledInputBaseDashboard
} from "../../../common/Form/UserManagementSearch"
import { useTheme } from "@mui/material/styles"
import "../../Style/fontStyle.css"
import { useSelector } from "react-redux"
import { jwtDecode } from "jwt-decode"

function GridLayout({
    handlefilter,
    handlePageChange,
    search,
    columns,
    isDataFetching
}) {
    const theme = useTheme()
    const user = JSON.parse(localStorage.getItem("user"))
    const isMobileOrTablet = useMediaQuery("(max-width: 1279px)")
    const isXs = useMediaQuery(theme.breakpoints.down("sm"))
    const decodedToken = jwtDecode(user?.idToken)
    // depending on user mail id -> We use base64 format to encrypt the user mail id., the data fetch from redux presist.
    const dashboardContent = useSelector(
        (state) => state.dashboard[btoa(decodedToken?.email)]
    )

    return (
        <div>
            {/** Gradient top banner background */}
            <Box
                sx={{
                    width: "200%",
                    marginLeft: "-50%",
                    height: "250px",
                    background:
                        "radial-gradient(at bottom center, #ffffff 0%, #643DD6 4%, #41288A 35%, #1E133E 65%);",
                    display: { xs: "none", sm: "block" },
                    position: "absolute",
                    top: "0px",
                    zIndex: "-10"
                }}
            ></Box>

            {/** Top dashboard cards */}
            <Box
                width="100%"
                sx={{
                    height: "auto",
                    paddingX: "10px",
                    backgroundColor: "rgba(0,0,0,0)",
                    marginTop: isXs ? "0px" : "24px"
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 2,
                        marginBottom: isXs ? "10px" : "30px"
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: "40px",
                            fontFamily: "The Seasons",
                            fontWeight: 600,
                            color: isXs ? "#1b1b1b" : "white",
                        }}
                        className="welcomeText"
                    >{`Welcome ${user?.firstName}`}</Typography>
                    <Box>
                        {isDataFetching && (
                            <CircularProgress
                                size={40}
                                sx={{ color: "brand.light" }}
                            />
                        )}
                    </Box>
                </Box>
                <DynamicDashboardCards
                    data={dashboardContent?.api1?.cardList}
                    cardsPerRow={3}
                />
            </Box>

            {/** Main Layout */}
            {isMobileOrTablet ? (
                <Grid container columnSpacing={1}>
                    {/** Mobile and tablet main display */}
                    <Grid
                        item
                        xs={12}
                        lg={12}
                        md={12}
                        sx={{ marginTop: "5px", marginBottom: "5px" }}
                    >
                        <Grid container columnSpacing={1}>
                            <Grid
                                item
                                xs={12}
                                lg={8}
                                sx={{ marginTop: "5px", marginBottom: "5px" }}
                            >
                                <BarChartController
                                    title={"TTM Aggregate AUM"}
                                    data={
                                        dashboardContent?.api1
                                            ?.ttmAggregateAumBarChart
                                    }
                                    category={"AUM"}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                lg={4}
                                sx={{ marginTop: "5px", marginBottom: "5px" }}
                            >
                                <PieChartController
                                    title={"Aggregate Sector Allocation"}
                                    data={
                                        dashboardContent?.api2
                                            ?.aggregateSectorAllocation
                                    }
                                    category={"Sector"}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                lg={6}
                                sx={{ marginTop: "5px", marginBottom: "5px" }}
                            >
                                <PieChartController
                                    title={"Aggregate Asset Allocation"}
                                    data={
                                        dashboardContent?.api2
                                            ?.aggregateAssetAllocation
                                    }
                                    category={"Asset"}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                lg={6}
                                sx={{ marginTop: "5px", marginBottom: "5px" }}
                            >
                                <PieChartController
                                    title={"Aggregate Geographical Allocation"}
                                    data={
                                        dashboardContent?.api2
                                            ?.geographicalAssetAllocation
                                    }
                                    category={"Geographical"}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            ) : (
                <Box
                    idth="100%"
                    sx={{ height: "auto", paddingX: "10px", paddingTop: "0px" }}
                >
                    {/** Desktop and laptop layout */}
                    <Grid container>
                        <Grid container item xs={12}>
                            <Grid
                                container
                                spacing={2}
                                sx={{
                                    maxHeight: "420px",
                                    minHeight: "410px",
                                    marginTop: 1
                                }}
                            >
                                <Grid item xs={12} md={8}>
                                    <BarChartController
                                        title={"TTM Aggregate AUM (US $)"}
                                        data={
                                            dashboardContent?.api1
                                                ?.ttmAggregateAumBarChart
                                        }
                                        category={"AUM"}
                                        type={true}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <PieChartController
                                        title={
                                            "Aggregate Sector Allocation (%)"
                                        }
                                        data={
                                            dashboardContent?.api2
                                                ?.aggregateSectorAllocation
                                        }
                                        category={"Sector"}
                                    />
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                spacing={2}
                                sx={{
                                    maxHeight: "420px",
                                    minHeight: "410px",
                                    marginY: 1
                                }}
                            >
                                <Grid item xs={12} md={6}>
                                    <PieChartController
                                        title={"Aggregate Asset Allocation (%)"}
                                        data={
                                            dashboardContent?.api2
                                                ?.aggregateAssetAllocation
                                        }
                                        category={"Asset"}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <PieChartController
                                        title={
                                            "Aggregate Geographical Allocation (%)"
                                        }
                                        data={
                                            dashboardContent?.api2
                                                ?.geographicalAssetAllocation
                                        }
                                        category={"Geographical"}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            )}

            {/** Secondary layout with tasks and other widgets */}

            {/** Task widget */}
            <Grid container sx={{ paddingX: "10px", marginTop: 2 }}>
                <Grid item lg={12} xs={12}>
                    <Grid
                        sx={{
                            height: "425px",
                            background: "#FFFFFF",
                            boxShadow: "none",
                            border: "1px solid #EBEBEB",
                            borderRadius: "12px",
                            padding: "10px"
                        }}
                    >
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Typography
                                sx={{
                                    padding: "13px 10px",
                                    fontSize: "18px",
                                    fontWeight: "500"
                                }}
                            >
                                Task
                            </Typography>
                            <div style={{ width: "200px" }}>
                                <Search sx={{ height: "30px" }}>
                                    <SearchIconWrapper>
                                        {" "}
                                        <SearchIcon />{" "}
                                    </SearchIconWrapper>
                                    <StyledInputBaseDashboard
                                        placeholder="Search"
                                        inputProps={{ "aria-label": "search" }}
                                        value={search}
                                        onChange={(e) => {
                                            handlefilter(e)
                                        }}
                                        sx={{
                                            "&.MuiInputBase-input": {
                                                padding: "0px !important"
                                            },
                                            "&::placeholder": {
                                                fontSize: "14px",
                                                fontWeight: "400",
                                                fontFamily: "Mona Sans"
                                            }
                                        }}
                                    />
                                </Search>
                            </div>
                        </div>
                        {dashboardContent?.api3?.list && (
                            <>
                                <GridTable
                                    data={"task"}
                                    rows={dashboardContent?.api3?.list}
                                    columns={columns}
                                ></GridTable>
                                <div
                                    style={{
                                        float: "right",
                                        alignItems: "end",
                                        justifyContent: "end",
                                        textAlign: "end",
                                        margin: "3px"
                                    }}
                                >
                                    <PaginationComponent
                                        handlePageChange={handlePageChange}
                                        page={dashboardContent?.api3?.page}
                                        totalCount={
                                            dashboardContent?.api3?.totalCount
                                        }
                                        size={"small"}
                                    />
                                </div>
                            </>
                        )}
                    </Grid>
                </Grid>
            </Grid>

            {/** Market News Widget */}
            <Grid
                container
                spacing={2}
                sx={{
                    marginTop: "5px",
                    marginBottom: "30px",
                    paddingX: "10px"
                }}
            >
                {/** Market News section */}
                <Grid item xs={12} lg={12}>
                    <div
                        style={{
                            height: "100%",
                            background: "#FFFFFF 0% 0% no-repeat padding-box",
                            boxShadow: "none",
                            border: "1px solid #DFD2D280",
                            borderRadius: "12px"
                        }}
                    >
                        {dashboardContent?.api4?.marketDetails?.length ===
                            0 && (
                            <Typography
                                sx={{ textAlign: "center", paddingTop: "14%" }}
                            >
                                No Data Available
                            </Typography>
                        )}
                        {dashboardContent?.api4?.marketDetails?.length !==
                            0 && (
                            <MarketNews
                                marketDetails={
                                    dashboardContent?.api4?.marketDetails
                                }
                            />
                        )}
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

export default GridLayout
